import { useState, useEffect } from "react";
import Nav from 'react-bootstrap/Nav';
import { Routes, NavLink, Route} from "react-router-dom";
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import Markdown from "markdown-to-jsx";	
import Code from "../../Common/Code";
import JS2 from './JS2';
import JSSidebar from '../JSSidebar';
import BackToTopBtn from "../../Common/BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const JS3 = () => { 
    const [postContent0, setPostcontent0] = useState('');
    const [postContent1, setPostcontent1] = useState('');
    const [postContent2, setPostcontent2] = useState('');
    const [postContent3, setPostcontent3] = useState('');
    const [postContent4, setPostcontent4] = useState('');
    const [postContent5, setPostcontent5] = useState('');
    const [postContent6, setPostcontent6] = useState('');
    const [postContent7, setPostcontent7] = useState('');
    const [isDark, setIsDark] = useState(true);

    useEffect(() => {
       
        import('./../JS-Tutorials/Tutorial12.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent0(response))
            .catch(err => console.log(err))
        )
          import('./../JS-Tutorials/Tutorial13.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent1(response))
              .catch(err => console.log(err))
          )
          import('./../JS-Tutorials/Tutorial14.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent2(response))
              .catch(err => console.log(err))
          )
          import('./../JS-Tutorials/Tutorial15.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent3(response))
              .catch(err => console.log(err))
          )
          import('./../JS-Tutorials/Tutorial16.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent4(response))
              .catch(err => console.log(err))
          )
          import('./../JS-Tutorials/Tutorial17.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent5(response))
              .catch(err => console.log(err))
          )
          import('./../JS-Tutorials/Tutorial18.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent6(response))
              .catch(err => console.log(err))
          )
          import('./../JS-Tutorials/Tutorial19.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent7(response))
              .catch(err => console.log(err))
          )
      }, [])

  return (
    <div className="overflow-hidden">
        <Helmet>
            <link rel="icon" href="images/takawale.ico"/>
            <title>Javascript | JS CheatSheet II</title>
            <meta name="description" content="JavaScript CheatSheet contains all that JavaScript developer must know"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3 className="text-white font-tertiary pt-2">JS CheatSheet</h3>
                    </div>
                </div>
            </div>
        </section>

        <div className=" pagination w-100 mt-3">
            <Routes>
                <Route  path="/js2" element={ <JS2/>} />
            </Routes>
        </div>  
        <Nav aria-label="Page navigation example">
            <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/js2"><b>Previous</b></NavLink>
        </Nav>
        <section className="section mb-200 mx-2">
            <div className="bg-blur rounded">
                 <div className="row p-3">
                    <div className="col-lg-12">

                        <p className="text-dark px-4">Published by <span className="text-dark font-Croissant">takawale.com</span></p>
                        <div className="content rounded">
                            <embed src="images/blog/js.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-100" />
                        </div>
                        <JSSidebar/>
                        <h4 className="text-dark pt-5">constructor</h4>
                        <li>In JavaScript, a <b>constructor function</b> is used to create objects.</li>
                        <li>In JavaScript, you can create multiple objects from a constructor function.</li>
                        <li>You can also create a constructor function with parameters.</li>

                        <h4 className="text-dark">Constructor Function Vs Object Literal</h4>
                        <p>Object Literal is generally used to create a single object.<br/> The constructor function is useful if you want to create multiple objects.</p>
                        <hr/>

                        <h4 className="text-dark">Prototype</h4>
                        <li>Prototype is very similar to the class which acts as the blueprint of the JavaScript objects.</li>
                        <li>The prototype is an object that is associated with every functions</li>
                        <li>Objects by default in JavaScript are accessible and modifiable, function's prototype property is an object, so it is accessible and modifiable </li>
                        <li>The prototype object is special type of enumerable object to which additional properties can be attached to it which will be shared across all the instances of it's constructor function.</li>
                        <li><b>Prototype</b> is used to provide additional property to all the objects created from a constructor function.</li>

                        <h4 className="text-dark">Prototype Inheritance</h4>
                        <p>In JavaScript, a prototype can be used to add properties and methods to a constructor function. And objects inherit properties and methods from a prototype.</p>
                        
                        <h4 className="text-dark">Changing Prototype</h4>
                        <p>If a prototype value is changed, then all the new objects will have the changed property value. <br/>All the previously created objects will have the previous value.</p>

                        <div className="p-2 rounded">
                            <article>
                                <header>
                                </header>
                                <main> 
                                    <Markdown
                                        options={{
                                        overrides: {
                                            Code: {
                                            component: Code,
                                            props: {
                                                isDark,
                                                setIsDark
                                            }
                                            }
                                        }
                                        }}
                                    >
                                        {postContent0}
                                    </Markdown>
                                </main>
                            </article>
                        </div>
                
                        <h4 className="text-dark">Prototype Chaining</h4>
                        <p>Prototypes are the means of inheritance in JavaScript. The prototype of an object would also have a prototype object. This continues until we reach the top level when there is no prototype object.<br/>
                        This is called <b>prototype chaining or prototype chain in JavaScript.</b></p>
                        <p>When we try to access any property of an object it is first checked in the own property of the object.<br/>
                        If the property does not exist in the own property then the prototype object is scanned for that property. This continues until we get the property we are accessing or we reach at to the end of prototype chain giving undefined.</p>
                        <p><b>Prototype chain</b> may have a negative impact on performance in the code where performance is of prime importance.<br/>
                        In order to avoid negative impact on performance in the code we can use the <b>hasOwnProperty method</b> which is inherited by all the objects from Object.prototype to limit the look up at a particular level.</p>
                        <p><b>hasOwnProperty</b> accepts the property name we are looking for and returns true or false based on whether the property is found or not.</p>
                    </div>
                    <div className="p-2 rounded">
                        <article>
                        <header>
                        </header>
                        <main> 
                        <Markdown
                            options={{
                            overrides: {
                                Code: {
                                component: Code,
                                props: {
                                    isDark,
                                    setIsDark
                                }
                                }
                            }
                            }}
                        >
                            {postContent1}
                        </Markdown>
                        </main>
                        </article>
                    </div>
            
                    <h4 className="text-dark">Accessors(Getters and setters Methods)</h4>
                    <p>It gives simpler syntax</p>
                    <li>It allows equal syntax for properties and methods</li>
                    <li>set - to define a setter method to set the property value</li>
            
                    <h4 className="text-dark">Why Using Getters and Setters?</h4>
                    <li>It gives simpler syntax</li>
                    <li>It can secure better data quality</li>
                    <li>set - to define a setter method to set the property value</li>
                    <li>It is useful for doing things behind-the-scenes</li>

                    <h4 className="text-dark">Object
                    .defineProperty()</h4>
                    <p>The <b>Object
                    .defineProperty()</b> method can also be used to add Getters and Setters</p>

                    <div className="p-2 rounded">
                    <article>
                        <header>
                        </header>
                        <main> 
                        <Markdown
                            options={{
                            overrides: {
                                Code: {
                                component: Code,
                                props: {
                                    isDark,
                                    setIsDark
                                }
                                }
                            }
                            }}
                        >
                            {postContent2}
                        </Markdown>
                        </main>
                    </article>
                    </div>

                    <h4 className="text-dark">Classes</h4>
                    <li>ECMAScript 2015, also known as ES6, introduced JavaScript Classes.</li>
                    <li>JavaScript Classes are templates for JavaScript Objects.</li>
                    <li>Use the keyword class to create a class.</li>
                    <li>Always add a method named constructor()</li>
                    <li>When you have a class, you can use the class to create objects.</li>

                    <h4 className="text-dark">Constructor Method</h4>
                    <li>It has to have the exact name "constructor"</li>
                    <li>It is executed automatically when a new object is created</li>
                    <li>It is used to initialize object properties</li>
                    <li>If you do not define a constructor method, JavaScript will add an empty constructor method.</li>
            
                    <h4 className="text-dark">Static Method</h4>
                    <li>A static method is a method that is defined in a class using the static keyword.</li>
                    <li>static method is different is that it cannot be accessed by any of the instantiated objects of the class. </li>
                    <li>Rather, the static method can only be accessed using the class name because it directly belongs to the class.</li>

                    <div className="p-2 rounded">
                        <article>
                            <header>
                            </header>
                            <main> 
                            <Markdown
                                options={{
                                overrides: {
                                    Code: {
                                    component: Code,
                                    props: {
                                        isDark,
                                        setIsDark
                                    }
                                    }
                                }
                                }}
                            >
                                {postContent3}
                            </Markdown>
                            </main>
                        </article>
                    </div>

            
                    <h4 className="text-dark">Modules</h4>
                    <li>JavaScript modules allow you to break up your code into separate files.</li>
                    <li>This makes it easier to maintain a code-base.</li>
                    <li>Modules are imported from external files with the <b>import</b> statement.</li>
                    <li>Modules also rely on <b>type="module"</b> in the <b>script tag</b>.</li>
                    <li>Modules with functions or variables can be stored in any external file.</li>
                    <li>There are two types of exports: <b>Named Exports and Default Exports.</b></li>

                    <h4 className="text-dark">Export</h4>
                    <p>Modules with functions or variables can be stored in any external file.<br/>
                    There are two types of exports: Named Exports and Default Exports.</p>

                    <h4 className="text-dark">Import</h4>
                    <p>You can import modules into a file in two ways, based on if they are named exports or default exports.<br/>
                    Named exports are constructed using curly braces. Default exports are not.</p>

                    <h4 className="text-dark">Note</h4>
                    <p>Modules only work with the HTTP(s) protocol.<br/>
                    A web-page opened via the file:// protocol cannot use import / export.</p>

                    <div className="p-2 rounded">
                        <article>
                            <header>
                            </header>
                            <main> 
                            <Markdown
                                options={{
                                overrides: {
                                    Code: {
                                    component: Code,
                                    props: {
                                        isDark,
                                        setIsDark
                                    }
                                    }
                                }
                                }}
                            >
                                {postContent4}
                            </Markdown>
                            </main>
                        </article>
                    </div>*

                    <h4 className="text-dark">Promises</h4>
                    <li><b>I Promise a Result!</b></li>
                    <li><b>Producing code</b> is code that can take some time</li>
                    <li><b>Consuming code</b> is code that must wait for the result</li>
                    <li>A Promise is a JavaScript object that links producing code and consuming code</li>
                    <li>A JavaScript Promise object can be:
                    <ol></ol>    
                    <ol><b>Pending</b></ol>
                    <ol><b>Fulfilled</b></ol>
                    <ol><b>Rejected</b></ol>
                    </li>
                    <li>The Promise object supports two properties: state and result.</li>
                    <li>While a Promise object is "pending" (working), the result is undefined.</li>
                    <li>When a Promise object is "fulfilled", the result is a value.</li>
                    <li>When a Promise object is "rejected", the result is an error object.</li>

                    <div className="p-2 rounded">
                        <article>
                            <header>
                            </header>
                            <main> 
                            <Markdown
                                options={{
                                overrides: {
                                    Code: {
                                    component: Code,
                                    props: {
                                        isDark,
                                        setIsDark
                                    }
                                    }
                                }
                                }}
                            >
                                {postContent5}
                            </Markdown>
                            </main>
                        </article>
                    </div>
                
                    <h4 className="text-dark">Asynchronous</h4>
                    <p>Asynchronous programming is a technique that enables your program to start a potentially long-running task and still be able to be responsive to other events while that task runs, rather than having to wait until that task has finished.</p>

                    <h4 className="text-dark">Async-Await</h4>
                    <li>async and await make promises easier to write</li>
                    <li><b>async</b> makes a function return a Promise</li>
                    <li><b>await</b> makes a function wait for a Promise</li>
                    <li>ECMAScript 2017 introduced the JavaScript keywords async and await.</li>
            
                    <h4 className="text-dark">Async</h4>
                    <p>The keyword async before a function makes the function return a promise</p>

                    <h4 className="text-dark">Await</h4>
                    <p>The <b>await</b> keyword can only be used inside an <b>async</b> function.<br/>
                    The <b>await</b> keyword makes the function pause the execution and wait for a resolved promise before it continues</p>

                    <div className="p-2 rounded">
                        <article>
                            <header>
                            </header>
                            <main> 
                            <Markdown
                                options={{
                                overrides: {
                                    Code: {
                                    component: Code,
                                    props: {
                                        isDark,
                                        setIsDark
                                    }
                                    }
                                }
                                }}
                            >
                                {postContent6}
                            </Markdown>
                            </main>
                        </article>
                    </div>

                    <h4 className="text-dark">Error handling</h4>
                    <p>JavaScript provides error-handling mechanism to catch runtime errors using try-catch-finally block, similar to other languages like Java or C#.</p>
                    <li><b>try:</b> wrap suspicious code that may throw an error in try block.</li>
                    <li><b>catch:</b> write code to do something in catch block when an error occurs. The catch block can have parameters that will give you error information. Generally catch block is used to log an error or display specific messages to the user.</li>
                    <li><b>finally:</b> code in the finally block will always be executed regardless of the occurrence of an error. The finally block can be used to complete the remaining task or reset variables that might have changed before error occurred in try block.</li>
                    <li><b>throw:</b> Use throw keyword to raise a custom error.</li>

                    <div className="p-2 rounded">
                        <article>
                            <header>
                            </header>
                            <main> 
                            <Markdown
                                options={{
                                overrides: {
                                    Code: {
                                    component: Code,
                                    props: {
                                        isDark,
                                        setIsDark
                                    }
                                    }
                                }
                                }}
                            >
                                {postContent7}
                            </Markdown>
                            </main>
                        </article>
                    </div>

                    <div className=" pagination w-100">
                        <Routes>
                            <Route  path="/js2" element={ <JS2/>} />
                        </Routes>
                    </div>  
                    <Nav aria-label="Page navigation example">
                        <NavLink className="page-link text-dark rounded btn-primary p-2 mb-5 mx-4"  to="/js2"><b>Previous</b></NavLink>
                    </Nav>
                </div>

            </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
    )
}

export default JS3

