import React, { useEffect } from "react";
import Nav from 'react-bootstrap/Nav';
import {Routes, NavLink, Route} from "react-router-dom";
import Carouselslider from "./Carouselslider";
import Contact from './Contact'
import Footer from "./Common/Footer";
import './home.css';
import BackToTopBtn from './Common/BackToTopBtn';
import TestimonialSlider1 from "./Portfolio/TestimonialSlider1";
import AboutMe from "./AboutMe";
import Services from "./Services"
import Header from "./Common/Header";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { Helmet } from 'react-helmet-async';

const Home = () => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
 
  return (
    <div>
      <Helmet>
        <link rel="icon" href="images/takawale.ico"/>
        <title>Home | Expert Web & Graphics Design, Development Services </title>
        <meta name="description" content="Elevate your brand with stunning websites, UI/UX design, and custom development. We create visually appealing, high-performing digital solutions tailored to your needs."/>
        <meta name="keywords" content='web design services, crafting visually stunning and user-friendly websites, custom layouts, responsive design'/>
      </Helmet>
      <Header/>
      <section className="section section-on-footer mb-200 bg-blur mx-2 mt-3 rounded" id="parallax">
        <div className="min-vh-100 pb-1 mb-3">
          <div className="col-lg-12 col-12 shuffle-item mb-3" data-groups="[&quot;design&quot;,&quot;illustration&quot;]">
              <Carouselslider className="bg-light" />
          </div>
          <div className="h-50" data-aos="fade-down">
            <Nav className="bg-light">
              <NavLink className="mx-auto"><label className="text-dark text-uppercase font-rounded fs-3 px-3 mt-1 fw-normal">About Me & My Skills</label></NavLink>
            </Nav>
            <AboutMe/>
          </div>
          <div className="h-50" data-aos="fade-down">
            <Nav className="bg-light">
              <NavLink className="mx-auto"><label className="text-dark text-uppercase font-rounded fs-3 px-3 mt-1 fw-normal">Services</label></NavLink>
            </Nav>
            <Services/>
          </div>
          <div  className="h-50" data-aos="fade-down">
            <Nav className="bg-light">
              <NavLink className="mx-auto"><label className="text-dark text-uppercase font-rounded fs-3 px-3 mt-1 fw-normal">Work Highlights</label></NavLink>
            </Nav>
            <TestimonialSlider1/>
          </div>
         
          <Nav className="shadow-lg bg-light" data-aos="fade-down">
            <NavLink className="mx-auto" to="/contact"><span className="text-dark text-uppercase hover-shadow-lg font-rounded btn btn-primary fs-5 shadow-lg-lg rounded-3 px-3 my-1 fw-normal"><i class="far fa-envelope fw-light fa-1x pe-2"></i>Let's Connect</span></NavLink>
          </Nav>
          <div className=" ">
            <Routes>
              <Route  path="/contact" element={ <Contact />} />
            </Routes>
          </div>
        </div>
       
      </section>
      <BackToTopBtn/>
      <Footer/>
    </div>
  )
}

export default Home
