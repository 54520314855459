import React, {useState, useEffect} from "react";
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import Markdown from "markdown-to-jsx";	
import { Helmet } from 'react-helmet-async';
import Header from "../../Common/Header";
import Footer from '../../Common/Footer';
import Code from "../../Common/Code";  
import Sidebar from "../Sidebar";
import BackToTopBtn from "../../Common/BackToTopBtn";


const RX1 = () => {
    const [postContent0, setPostcontent0] = useState('');
    const [isDark, setIsDark] = useState(true);
    
    useEffect(() => {
       
        import('./../RXJS-Tutorials/Tutorial0.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent0(response))
            .catch(err => console.log(err))
        )
      }, [])
   
  return (
    <div className="overflow-hidden">
        <Helmet>
            <link rel="icon" href="images/takawale.ico"/>
            <title>React-Reduc Blog Info I</title>
            <meta name="description" content="ReactJs Tutorial Blog"/>
        </Helmet>
        <Header/>
        <section className="bg-primary position-relative">
            <div className="container">
                <div className="row">
                <div className="col-12 text-center">
                    <h3 className="text-white font-tertiary pt-2">React-Redux</h3>
                </div>
                </div>
            </div>
        </section>
        <section className="section mb-200 px-3">
            <div className=" pagination w-100">
                <Routes>
                    <Route  path="/rx1" element={ <RX1 />} />
                </Routes>
            </div>  
            <Nav aria-label="Page navigation example">
                <NavLink className="page-link text-white rounded btn-primary p-2 mx-5"  to="/rx1"><b>Next</b></NavLink>
            </Nav>
            <div className="bg-blur rounded">
                <div className="row p-3">
                    <div className="col-lg-12">
                        <p className="text-white px-4">Published by <span className="text-white font-Croissant">takawale.com</span></p>
                        <div className="content rounded">
                            <embed src="images/blog/Redux.png" alt="post-thumb" className="img-fluid rounded float-center w-100 mr-5 mb-4" />
                            <div>
                                <Sidebar/>
                            </div>
                            <h4 className="text-dark mt-5"><i class="fas fa-feather bg-dark text-white rounded-3 me-1"></i>What is Redux and why it is used?</h4>
                            <p>Redux is a popular state management library, providing a predictable way to handle state changes.<br/> 
                            While it's mostly used as a state management tool with React, you can use it with any other JavaScript framework or library.</p>
                            <p>With Redux, the state of your application is kept in a store, and each component can access any state that it needs from this store.</p>

                            <h4 className="text-dark">When to use Redux</h4>
                            <p>Redux is an external librarie for managing states in your javaScript applications. <br/>
                            It should be used in JavaScript applications whenever your application is to large and it's gets difficult for you to manage and maintain the state in the application.</p>
                            
                            <h4 className="text-dark mt-3"><i class="fas fa-feather bg-dark text-white rounded-3 me-1"></i>What is Redux Tool Kit?</h4>
                            <p>Redux Toolkit is an opinionated, batteries-included package that simplifies the Redux workflow. It provides utilities that streamline common Redux tasks, such as creating actions and reducers.</p>

                            <h4 className="text-dark mt-3"><i class="fas fa-feather bg-dark text-white rounded-3 me-1"></i>How to use Redux in ReactJS explained with examples.</h4>
                            
                            <h4 className="text-dark">Creating a new ReactJS based project and adding Redux to it</h4>
                            <li>First things first let’s create a new react app</li>
                            <p className="p-4 mt-4 border border-dark rounded">
                                <b>create-react-app react-redux-webapp</b><br/>
                                <b>cd react-redux-webapp</b><br/>
                                <b>npm start</b>
                            </p>
                            <p className="text-center text-dark fw-bold">See below the output of the above commonds</p>
                            <embed src="images/blog/reactgif.gif" alt="post-thumb" className="img-fluid rounded float-center w-100 mr-5 mb-4" />
                            
                            <li>let’s go ahead and add the following Redux packages:</li>
                            <p className="p-4 mt-4 border border-dark rounded">
                                <b> using npm <br/>
                                    install --save redux react-redux</b><br/>
                                <b> using yarn<br/>
                                    yarn add react-redux</b>
                            </p>
                            <p className="text-center text-dark"> You'll also need to install Redux and set up a Redux store in your app.</p>

                            <p className="text-center text-dark"> React-Redux v8 is written in TypeScript, so all types are automatically included.</p>
                            
                            <h4 className="text-dark">redux v9.1.0</h4>
                            <li>What Redux does in a very general sense, is that it creates a global state for the whole application, that can be accessed by any of your component</li>
                            <li>It is a state management library</li>
                            <li>You have only one state for your whole app, and not states for each of your components</li>
                            
                            <h4 className="text-dark">react-redux v8.1.2</h4>
                            <li>React Redux is the official React UI bindings layer for Redux. It lets your React components read data from a Redux store, and dispatch actions to the store to update state.</li>

                            <p><i class="fas fa-arrow-circle-right me-1 mt-3 text-dark"></i>The React-Redux workflow is a unidirectional data flow architecture that helps you build complex and scalable React applications. It is based on three core components: actions, store, and reducers.</p>

                            <p className="bg-dark rounded-3 p-3 my-4 text-white">The whole global state of your app is stored in an object tree inside a single store. The only way to change the state tree is to create an action, an object describing what happened, and dispatch it to the store. To specify how state gets updated in response to an action, you write pure reducer functions that calculate a new state based on the old state and the action.</p>
                           

                            <h4 className="text-dark pt-4">When using Redux with React, you will need three main things:</h4>
                            <p><span className="fw-normal bg-dark badge">Actions</span> <br/>sends data from our React app to the Store, Store is the place where we store all our component data from our app and can fetch the data from the store whenever we want.</p>
                            <p><span className="fw-normal bg-dark badge">Reducer</span> <br/>is a function that takes the current state and an action and returns a new state. The reducer is called every time there is an update to the application’s state. They are responsible for updating the application state in response to actions.s</p>
                            <p><span className="fw-normal bg-dark badge">Store</span> <br/>is the single source of truth for our application. It contains all the data that can be used to render the UI. It brings the actions and reducers together, holding and changing the state for the whole app — there is only one store.</p>

                            <h4 className="text-dark pt-4">Essentially, Redux is characterized by three main working principles:</h4>
                            <p>
                                <span className="fw-normal bg-dark badge">Single source of truth</span><br/>
                                The global state of your entire application is stored in an object tree within a single store.
                                Redux stores all of your application's different states and their data in a global object.<br/>
                                This architecture makes working with data and carrying out operations on state data easier.
                            </p>
                            <p>
                                <span className="fw-normal bg-dark badge">State is Read-only</span><br/>
                                The only way to change the state in a Redux application is by dispatching an action. 
                                This means that any changes made to the state must be done through action, and the state itself cannot be directly changed. <br/>
                                This ensures that all changes made to the state are intentional and predictable.
                                Furthermore, it helps to maintain the integrity of the application by ensuring that the state is always consistent and up-to-date across all components.
                            </p>
                            <p>
                            <span className="fw-normal bg-dark badge">Changes are made with pure functions</span><br/>
                                State changes in Redux applications are handled via pure functions known as Reducers. They take the previous state, carry out an operation on them and then return a new value which would be set as the new state.<br/>
                                Any operation that needs to be carried out on our application state must be done with reducers, as this makes the codebase more organized and prevent errors that could arise from conflicting updates.
                            </p>

                            <h4 className="text-dark mt-4">How React-Redux architecture work?</h4>
                            <p>1. React Component pass event to  the action creater.</p>
                            <p>2. Action creater gets an event based on that event it creates an action.</p>
                            <p>3. Then the created action is dispatched to the redux store.</p>
                            <p>4. In redux store we create reducer function which is a pure function that takes the dispatched action and relative state from the store, performs the desired action on the state and provides new state or an output of the dispatched action.</p>
                            <p>5. Readux Store then provides the new created state to the React Component.</p>
                            <embed src="images/blog/rxjsprinciple.png" alt="post-thumb" className="img-fluid rounded float-center w-100 mr-5 my-4" />

                            <h4 className="text-dark"><i class="fas fa-arrow-circle-right me-1 mt-3 text-dark"></i> Real time example of React-Redux work flow</h4>
                            <p>1. Consider we have created a Recipe-Management site.</p>
                            <p>2. In that we have a recipe component where we show all the recipes or provide the search box to search the recipes.</p>
                            <p>3. Customer visit the site opens the recipe page and goes to the perticular recipe image clicks the view recipe button placed below the image or search the particular recipe by typing the recipe name into the search box.</p>
                            <p>4. Next we create an Action Creater where the onClick event (This event pass request to the action creater to provide perticular recipe from the recipe data stored in the state object tree inside the react  store) is passed as soon as the view recipe button is clicked or search box is triggered.</p>
                            <p>5. This Action Creater contains a function that accepts the perticular event as props from the view, creating an action which is dispatch to the redux store.</p>
                            <p>6. Redux store then gets the dispatched action and provides this action (this action contains the request to get perticular recipe details) to the reducer function created within the redux store, in this reducer function we provide logic that fetch perticular recipe from a single state object stored inside the redux store.</p>
                            <p>NOTE- This single state object is an object tree where we store all our recipes we want to show on our recipe page.</p>
                            <p>7. This recipe details is then passed to the view where seperate page is displayed that shows the said recipe details to the customer.</p>
                            

                            <div className="p-2 rounded">
                            <article>
                                <header>
                                </header>
                                <main>
                                <Markdown
                                    options={{
                                    overrides: {
                                        Code: {
                                        component: Code,
                                        props: {
                                            isDark,
                                            setIsDark
                                        }
                                        }
                                    }
                                    }}
                                >
                                    {postContent0}
                                </Markdown>
                                </main>
                            </article>
                        </div>
                        <div className=" pagination w-100">
                            <Routes>
                                <Route  path="/rx1" element={ <RX1 />} />
                            </Routes>
                        </div>  
                        <Nav aria-label="Page navigation example">
                            <NavLink className="page-link text-white rounded btn-primary p-2 mx-5"  to="/rx1"><b>Next</b></NavLink>
                        </Nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
    )
}

export default RX1

