import React, {useState, useEffect} from "react";
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import Markdown from "markdown-to-jsx";	
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import Code from "../../Common/Code";
import RJS5 from './RJS5';
import RJS7 from './RJS7';
import Sidebar from "../Sidebar";
import BackToTopBtn from "../../Common/BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const RJS6= () => {
    const [postContent0, setPostcontent0] = useState('');
    const [postContent1, setPostcontent1] = useState('');
    const [postContent2, setPostcontent2] = useState('');
    const [isDark, setIsDark] = useState(true);

    useEffect(() => {
       
        import('./../ReactJS-Tutorials/Tutorial18.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent0(response))
            .catch(err => console.log(err))
        )
        import('./../ReactJS-Tutorials/Tutorial19.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent1(response))
            .catch(err => console.log(err))
        )
        import('./../ReactJS-Tutorials/Tutorial20.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent2(response))
            .catch(err => console.log(err))
        )
      }, [])
   
   
  return (
    <div className="overflow-hidden">
        <Helmet>
            <link rel="icon" href="images/takawale.ico"/>
            <title>React Js Blog Info VII</title>
            <meta name="description" content="ReactJs Tutorial Blog"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
            <div className="container">
                <div className="row">
                <div className="col-12 text-center">
                    <h3 className="text-white font-tertiary pt-2">ReactJS</h3>
                </div>
                </div>
            </div>
        </section>
        <div className=" pagination w-100 mt-3">
            <Routes>
                <Route  path="/rjs5" element={ <RJS5/>} />
                <Route  path="/rjs7" element={ <RJS7 />} />
            </Routes>
        </div>  
        <Nav aria-label="Page navigation example">
            <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/rjs5"><b>Previous</b></NavLink>
            <NavLink className="page-link text-dark rounded btn-primary p-2"  to="/rjs7"><b>Next</b></NavLink>
        </Nav>
        <section className="section mb-200 mx-2">
            <div className="bg-blur rounded">
                <div className="row p-3">
                    <div className="col-lg-12">
                        
                        <p className="text-dark px-4">Published by <span className="text-dark font-Croissant">takawale.com</span></p>
                        <div className="content rounded">
                            <embed src="images/blog/ReactJS.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-100" />
                            <div>
                                <Sidebar/>
                            </div>
                            <h4 className="text-dark pt-5">13. useSyncExternalStore Hook</h4>
                            <p>The <b>useSyncExternalStore</b>  is a hook that was created for state management libraries.<r/> 
                            Its purpose is to provide an ability to read and subscribe from external data sources in a way that works with concurrent rendering features like selective hydration and time slicing.</p>
                            <p>An external store needs to provide at least two arguments—subscribe and get state snapshot methods. </p>

                            <p><b>Here’s a simple example of how to use the useSyncExternalStore hook.</b></p>

                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                const state = useSyncExternalStore(store.subscribe, store.getState);
                                </b>
                            </div>

                            <p>The store.getState method returns the whole external state, but we can also pass a function that returns only a part of it. For instance, if the store has a field called name, we could get just the name value from the store state.</p>
                           
                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                               const state = useSyncExternalStore(<br/>
                                store.subscribe, <br/>
                                () =&#65310; store.getState().name<br/>
                                );
                                </b>
                            </div>

                            <p>The useSyncExternalStore can also accept a third argument, which can be used to provide a state snapshot that was created if the React app was server-side rendered.<br/>
                            We won’t be diving into it in this article, as server-side rendering comes with its own rules and setup that is out of the scope for this article.</p>
                        
                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                const state = useSyncExternalStore(<br/>
                                store.subscribe, <br/>
                                store.getState, <br/>
                                () =&#65310; INITIAL_SERVER_SNAPSHOT<br/>
                                );
                                </b>
                            </div>
                            <p>That’s a nice explanation so far, but how could we use it in practice?<br/>
                            Here’s a mini Zustand implementation utilizing the useSyncExternalStore hook.</p>
                            <div className="p-2 rounded">
                                <article>
                                    <header>
                                    </header>
                                    <main> 
                                    <Markdown
                                        options={{
                                        overrides: {
                                            Code: {
                                            component: Code,
                                            props: {
                                                isDark,
                                                setIsDark
                                            }
                                            }
                                        }
                                        }}
                                    >
                                        {postContent0}
                                    </Markdown>
                                    </main>
                                </article>
                            </div>

                            <h4 className="text-dark">The createStore creates a new state and a few methods:</h4>
                            <li><b>subscribe</b>– adds a new listener that will be notified when the state changes</li>
                            <li><b>setState </b>– updates the state in an immutable way by utilizing immer and notifies all subscribers</li>
                            <li><b>getState</b>– returns current state</li>
                            <li><b>useStore </b>– a wrapper around useSyncExternalStore that can be used to consume the store state</li>

                            <h4 className="text-dark">14. useInsertionEffect Hook</h4>
                            <p>The useInsertionEffect should only be used by CSS-in-JS libraries to dynamically insert styles into the DOM. <br/>
                            This hook has an identical signature to useEffect, but it runs synchronously before all DOM mutations. <br/>
                            Thus, if you’re not injecting any CSS styles into the DOM, you shouldn’t use it.</p>
                            <div className="p-2 rounded">
                                <article>
                                    <header>
                                    </header>
                                    <main> 
                                    <Markdown
                                        options={{
                                        overrides: {
                                            Code: {
                                            component: Code,
                                            props: {
                                                isDark,
                                                setIsDark
                                            }
                                            }
                                        }
                                        }}
                                    >
                                        {postContent1}
                                    </Markdown>
                                    </main>
                                </article>
                            </div>

                            <h4 className="text-dark">15. useImperativeHandle Hook</h4>
                            <p>The <b>useImperativeHandle</b> hook works in the similar phase of useRef hook but only it allows us to modify the instance that is going to be passed with the ref object which provides a reference to any DOM element. <br/>
                            Although this hook is used in rare cases, it has some most advanced functionality.</p>
                            <p><b>useImperativeHandle</b> hooks is different from the useRef hook in majorly two ways −</p>
                            <li>It allows handling and customizing the returned value explicitly.</li>
                            <li>It allows you to replace the native instances of the ref object with the user-defined ones.</li>
                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                    Syntax: <br/>
                                useImperativeHandle(ref, createHandle, [deps])
                                </b>
                            </div>
                            
                            <p><b>Example of useImperativeHandle React Hook</b></p>
                           
                            <div className="p-2 rounded">
                                <article>
                                    <header>
                                    </header>
                                    <main> 
                                    <Markdown
                                        options={{
                                        overrides: {
                                            Code: {
                                            component: Code,
                                            props: {
                                                isDark,
                                                setIsDark
                                            }
                                            }
                                        }
                                        }}
                                    >
                                        {postContent2}
                                    </Markdown>
                                    </main>
                                </article>
                            </div>

                        <div className=" pagination w-100">
                            <Routes>
                                <Route  path="/rjs5" element={ <RJS5/>} />
                                <Route  path="/rjs7" element={ <RJS7 />} />
                            </Routes>
                        </div>  
                        <Nav aria-label="Page navigation example">
                            <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/rjs5"><b>Previous</b></NavLink>
                            <NavLink className="page-link text-dark rounded btn-primary p-2"  to="/rjs7"><b>Next</b></NavLink>
                        </Nav>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
    )
}

export default RJS6
