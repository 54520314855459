import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import BackToTopBtn from '../Common/BackToTopBtn';
import BackButton from '../Common/BackButton';
import { Helmet } from 'react-helmet-async';

const Ftbee = () => {
  return (
      <div className="overflow-hidden">
        <Helmet>
          <link rel="icon" href="images/takawale.ico"/>
          <title>Blogger website Info</title>
          <meta name="description" content="Info of blogger website"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
        <div className="container">
            <div className="row">
            <div className="col-12 text-center">
              <h3 className="text-white font-tertiary pt-2">ftbee blogger website</h3>
            </div>
            </div>
        </div>
        
        
        </section>


        <section className="section mb-200 mx-2">
          <div className="bg-blur rounded">
            <div className="row p-3">
              <div className="col-lg-12">
                <div className="content p-3 rounded">
                <embed src="images/portfolio/ftbee-web-app/1.png" alt="post-thumb" className="img-fluid p-3 w-100"/>
                  <h4 className="text-dark">Project Overview</h4>
                  <p>Ftbee "Bee Foodie. Bee Trendy." blogger website. This website is dedicated to food and latest trends. This website promots the idea of spending time with your loved ones, creating your space where you can spend time with your family and frends. Dine with your family at your home rather visiting a restaurent cook different food dishes with your family at your home, travel destinations with your family and friends, Know what is good and what is not from what you get in the markert may it be food products, goods for your daily use, or goods you need for your home.</p> 
                  <p>This is the website where you can get</p>
                    <li>Recipes of different food dishes.</li>
                    <li>Blogs related to food, latest trends, travel destinations.</li>
                    <li>info. and comparision of products available in the market issentiel for daily usage.</li>
                    <li>You may expect ecommarce rlated activity associated with this website some times in the future.</li>
                  <p>This website is easy to use and understand for a new non-technical user.</p>
                  <p>With the requirement we gathered from our <b className='text-warningas'>research and survey,</b> we decided to develop a website that is user-friendly, contain easy information access, is easy to maintain, clean and classic design focus on attracting potential user and ultimately focus on increasing user traffic.</p>
                  
                  <h4 className="text-dark">Why do we think there is a need for such a kind of Website?</h4>
                  <p>During our research, we came across multiple blogging websites national as well as international where you can see-</p>
                    <li>food recipes,</li>
                    <li>product reviews and much other stuff.</li> 
                  <p>But those blogger websites can not be called an on-touch guide that provides information about food, fashion trains, and travel info.</p>
                  <p>With the fast developing world and most of our time spent in the office or for the business we do. There is a need, we should spend time with our family and friends, cook food together have fun, go on a trip with them, and look good on every occasion.</p>

                  <p><b>We through this site aim to:</b></p>
                    <li>provide different recipes based on different categories which can be prepared on different occasions, different seasons etc.</li>
                    <li>provide information about different travel destinations and how can we reach there.</li>
                    <li>Write useful blogs and compare different food items and publish short info. about it.</li>
                    <div className="row pt-3">
                      <div className="col-12">
                          <div className="">
                              <embed src="images/portfolio/ftbee-web-app/8.png" alt="post-thumb" className="img-fluid mx-auto d-block w-100" /> 
                          </div>
                      </div>
                    </div>
                  <h4 className="text-dark">Create a Responsive Blogger Website</h4>
                  <p>We aim to create a well-thought-out responsive web design to deliver a faster loading experience on all devices.</p>
                  
                  <h4 className="text-dark">Impress With Efficient UI/UX Design</h4>
                  <p>We strive hard to provide stunning UI/UX solutions with simple yet effective blogger website design.
                  </p>

                  <h4 className="text-dark">Deliver User-Friendly Designs</h4>
                  <p>We provide functional web designs that users can easily understand and use to get the information they need from this website.</p>

                  <h4 className="text-dark">Build a Functional Admin Panel</h4>
                  <p>Our goal is to create a functional and user-friendly admin panel to allow all managing content manually (for website administration).</p>

                  <h4 className="text-dark">Problem Statement</h4>
                  <p>The simple vision is to create an online website that will act as a guide, guiding you by providing information about food, travel and fashion.</p>
                  <p>Keeping in mind that this will be categorized is three segments food, travel, and fashion, the website will contain lots of information and lots of pages. this will need proper management and arrangement of the information.</p>
                  <p>The Website can eventually become complex to navigate for the end user, he might be confused by seeing such a large amount of information. Eventually generating a wrong impression or misconception about the purpose this website tends to fulfill.</p>
                  <p>We should have a well-documented site map for this website. There must be a proper categorization of the information provided on the website. Provide easy navigation and pagination system for the user to traverse from one page to another. Information provided on the website should be well documented, short, and use simple understandable language.</p>

                  <div className="row pt-3">
                  <BackButton/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
  )
}

export default Ftbee
