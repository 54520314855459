import { useState, useEffect } from "react";
import Nav from 'react-bootstrap/Nav';
import { Routes, NavLink, Route} from "react-router-dom";
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import Markdown from "markdown-to-jsx";	
import Code from "../../Common/Code";
import JS1 from './JS1';
import JS3 from './JS3';
import JSSidebar from '../JSSidebar';
import BackToTopBtn from "../../Common/BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const JS2 = () => { 
    const [postContent0, setPostcontent0] = useState('');
    const [postContent1, setPostcontent1] = useState('');
    const [postContent2, setPostcontent2] = useState('');
    const [postContent3, setPostcontent3] = useState('');
    const [postContent4, setPostcontent4] = useState('');
    const [postContent5, setPostcontent5] = useState('');
    const [isDark, setIsDark] = useState(true);

    useEffect(() => {
       
        import('./../JS-Tutorials/Tutorial6.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent0(response))
            .catch(err => console.log(err))
        )
          import('./../JS-Tutorials/Tutorial7.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent1(response))
              .catch(err => console.log(err))
          )
          import('./../JS-Tutorials/Tutorial8.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent2(response))
              .catch(err => console.log(err))
          )
          import('./../JS-Tutorials/Tutorial9.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent3(response))
              .catch(err => console.log(err))
          )
          import('./../JS-Tutorials/Tutorial10.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent4(response))
              .catch(err => console.log(err))
          )
          import('./../JS-Tutorials/Tutorial11.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent5(response))
              .catch(err => console.log(err))
          )
      }, [])

  return (
    <div className="overflow-hidden">
        <Helmet>
            <link rel="icon" href="images/takawale.ico"/>
            <title>Javascript | JS CheatSheet II</title>
            <meta name="description" content="JavaScript CheatSheet, contains all that JavaScript developer must know"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
            <div className="container">
                <div className="row">
                <div className="col-12 text-center">
                    <h3 className="text-white font-tertiary pt-2">JS CheatSheet</h3>
                </div>
                </div>
            </div>
        
        </section>

        <div className=" pagination w-100 mt-3">
            <Routes>
                <Route  path="/js1" element={ <JS1 />} />
                <Route  path="/js3" element={ <JS3 />} />
            </Routes>
        </div>  
        <Nav aria-label="Page navigation example pb-3">
            <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/js1"><b>Previous</b></NavLink>
            <NavLink className="page-link text-dark rounded btn-primary p-2 "  to="/js3"><b>Next</b></NavLink>
        </Nav>
        <section className="section mb-200 mx-2">
            <div className="bg-blur rounded">
                <div className="row p-3">
                    <div className="col-lg-12">
                        
                        <p className="text-dark px-4">Published by <span className="text-dark font-Croissant">takawale.com</span></p>
                        <div className="content rounded">
                            <embed src="images/blog/js.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-100" />
                            <div>
                                <JSSidebar/>
                            </div>
                            <h4 className="text-dark pt-5">Functions</h4>
                            <p>A JavaScript function is a block of code designed to perform a particular task.<br/>
                            A JavaScript function is executed when "something" invokes it (calls it).<br/>
                            </p>
                        
                            <h4 className="text-dark">Function Syntax</h4>
                            <p>A JavaScript function is defined with the function keyword, followed by a name, followed by parentheses ().<br/>
                            Function names can contain letters, digits, underscores, and dollar signs (same rules as variables).<br/>
                            The parentheses may include parameter names separated by commas:
                            (parameter1, parameter2, ...)<br/>
                            The code to be executed, by the function, is placed inside curly brackets: {}<br/>
                            Function parameters are listed inside the parentheses () in the function definition.<br/>
                            Function arguments are the values received by the function when it is invoked.<br/>
                            Inside the function, the arguments (the parameters) behave as local variables.</p>

                            <h4 className="text-dark">Function Invocation(Calling)</h4>
                            <p>The code inside the function will execute when "something" invokes (calls) the function:</p>
                            <li>When an event occurs (when a user clicks a button)</li>
                            <li>When it is invoked (called) from JavaScript code</li>
                            <li>Automatically (self invoked)</li>
                        
                        
                            <h4 className="text-dark">Return Keyword</h4>
                            <p>When JavaScript reaches a return statement, the function will stop executing.<br/>
                            If the function was invoked from a statement, JavaScript will "return" to execute the code after the invoking statement.<br/>
                            Functions often compute a return value. The return value is "returned" back to the "caller":</p>
                        
                        </div>    
                        
                        <div className="p-2 rounded">
                            <article>
                                <header>
                                </header>
                                <main> 
                                <Markdown
                                    options={{
                                    overrides: {
                                        Code: {
                                        component: Code,
                                        props: {
                                            isDark,
                                            setIsDark
                                        }
                                        }
                                    }
                                    }}
                                >
                                    {postContent0}
                                </Markdown>
                                </main>
                            </article>
                        </div>
                        
                        <h4 className="text-dark">Anonymous Function</h4>
                        <li>When a function is defined without a name, it’s known as an anonymous function.</li>
                        <li>They are stored in a variable and are automatically called using the variable name. </li>
                        <li>An anonymous function can be used as a parameter to other functions or as an immediately invoked function (IIF) execution.</li>
                        <li>An anonymous function are often used as the argument to another function to be run later as a callback function.</li>

                        <div className="p-2 rounded">
                            <article>
                                <header>
                                </header>
                                <main> 
                                <Markdown
                                    options={{
                                    overrides: {
                                        Code: {
                                        component: Code,
                                        props: {
                                            isDark,
                                            setIsDark
                                        }
                                        }
                                    }
                                    }}
                                >
                                    {postContent1}
                                </Markdown>
                                </main>
                            </article>
                        </div>

                        <h4 className="text-dark">Arrow Functions (ES6)</h4>
                        <li>Arrow functions are a clear and concise method of writing normal/regular Javascript functions in a more accurate and shorter way.</li>
                        <li>Arrow functions were introduced in the ES6 version.</li> 
                        <li>They make our code more structured and readable.</li>
                        <li>Arrow functions are anonymous functions i.e. they are functions without a name and are not bound by an identifier. </li>
                        <li>Arrow functions do not return any value and can be declared without the function keyword.</li>
                        <li>They are also called Lambda Functions.</li>
                        <div className="p-2 rounded">
                            <article>
                                <header>
                                </header>
                                <main> 
                                <Markdown
                                    options={{
                                    overrides: {
                                        Code: {
                                        component: Code,
                                        props: {
                                            isDark,
                                            setIsDark
                                        }
                                        }
                                    }
                                    }}
                                >
                                    {postContent2}
                                </Markdown>
                                </main>
                            </article>
                        </div>

                        <h4 className="text-dark">Loops</h4>
                        <p>Loops are handy, if you want to run the same code over and over again, each time with a different value.</p>

                        <h4 className="text-dark">Different Kinds of Loops</h4>
                        <li><b>for - </b>loops through a block of code a number of times</li>
                        <li><b>for/in - </b> loops through the properties of an object</li>
                        <li><b>for/of - </b>loops through the values of an iterable object</li>
                        <li><b>while - </b>loops through a block of code while a specified condition is true</li>
                        <li><b>do/while - </b>is a variant of the while loop. This loop will execute the code block once, before checking if the condition is true, then it will repeat the loop as long as the condition is true.</li>

                        <div>
                            <article>
                                <header>
                                </header>
                                <main>
                                <Markdown
                                    options={{
                                    overrides: {
                                        Code: {
                                        component: Code,
                                        props: {
                                            isDark,
                                            setIsDark
                                        }
                                        }
                                    }
                                    }}
                                >
                                    {postContent3}
                                </Markdown>
                                </main>
                            </article>
                        </div>
                        <h4 className="text-dark">Object</h4>
                        <li>In JavaScript, objects are king. If you understand objects, you understand JavaScript.</li>
                        <li>All JavaScript values, except primitives, are objects.</li>
                        <li>Objects are mutable: They are addressed by reference, not by value.</li>
                        <hr/>
                        <h4 className="text-dark">Primitives</h4>
                        <li>A <b>primitive value</b> is a value that has no properties or methods.</li>
                        <li><b>3.14</b> is a primitive value</li>
                        <li>A <b>primitive data type</b> is data that has a primitive value.</li>
                        <li>Primitive values are immutable (they are hardcoded and cannot be changed).</li>
                        <li>if x = 3.14, you can change the value of x, but you cannot change the value of 3.14.</li>

                        <p>JavaScript defines 7 types of primitive data types:</p>
                        <li>string</li>
                        <li>number</li>
                        <li>boolean</li>
                        <li>null</li>
                        <li>undefined</li>
                        <li>symbol</li>
                        <li>bigint</li>
                        <div >
                            <article>
                                <header>
                                </header>
                                <main>
                                <Markdown
                                    options={{
                                    overrides: {
                                        Code: {
                                        component: Code,
                                        props: {
                                            isDark,
                                            setIsDark
                                        }
                                        }
                                    }
                                    }}
                                >
                                    {postContent4}
                                </Markdown>
                                </main>
                            </article>
                        </div>

                        <h4 className="text-dark">What is this?</h4>
                        <p>The this keyword refers to different objects depending on how it is used:</p>
                        <li>In an object method, this refers to the object.</li>
                        <li>Alone, this refers to the global object.</li>
                        <li>In a function, this refers to the global object.</li>
                        <li>In a function, in strict mode, this is undefined.</li>
                        <li>In an event, this refers to the element that received the event.</li>
                        <li>Methods like call(), apply(), and bind() can refer this to any object.</li>

                        <p>*<b>this</b> is not a variable. It is a keyword. You cannot change the value of this.</p>
                        <hr/>
                        <h4 className="text-dark">Iterators</h4>
                        <li>Iterables are iterable objects (like Arrays).</li>
                        <li>Iterables can be iterated over with for..of loops</li>
                        <li>Technically, iterables must implement the Symbol.iterator method.</li>
                        <li>The iterator protocol defines how to produce a sequence of values from an object.</li>
                        <li>An object becomes an iterator when it implements a next() method.</li>
                        <li>The next() method must return an object with two properties:
                            <ol><b>value (the next value):</b> The value returned by the iterator
                            (Can be omitted if done is true)</ol>
                            <ol><b>done (true or false):</b> true if the iterator has completed
                            false if the iterator has produced a new value</ol>
                        </li>
                        <li>The Symbol.iterator is a function that returns a next() function.</li>
                        <li>An iterable can be iterated over with the code: for (const x of iterable) { }</li>
                        <hr/>
                        <h4 className="text-dark">Set()</h4>
                        <p>A JavaScript Set is a collection of unique values.<br/>
                        Each value can only occur once in a Set.<br/>
                        A Set can hold any value of any data type.</p>
                        <table className="table text-dark">
                            <tbody>
                                <tr>
                                    <th scope="col" className="border border-dark border-right rounded">
                                        Method
                                    </th>
                                    <th scope="col" className="border border-dark border-right rounded">
                                    Description
                                    </th>
                                </tr>
                                <tr>
                                    <th className="border border-dark border-right rounded">
                                        new Set()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Creates a new Set
                                    </td>
                                </tr>
                                <tr>
                                    <th className="border border-dark border-right rounded">
                                        add()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Adds a new element to the Set
                                    </td>
                                </tr>
                                <tr>
                                    <th className="border border-dark border-right rounded">
                                        delete()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Removes an element from a Set
                                    </td>
                                </tr>
                                <tr>
                                    <th className="border border-dark border-right rounded">
                                        has()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Returns true if a value exists
                                    </td>
                                </tr>
                                <tr>
                                    <th className="border border-dark border-right rounded">
                                        clear() 
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Removes all elements from a Set
                                    </td>
                                </tr>
                                <tr>
                                    <th className="border border-dark border-right rounded">
                                        forEach()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Invokes a callback for each element
                                    </td>
                                </tr>
                                <tr>
                                    <th className="border border-dark border-right rounded">
                                        values()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Returns an Iterator with all the values in a Set
                                    </td>
                                </tr>
                                <tr>
                                    <th className="border border-dark border-right rounded">
                                        keys()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Same as values()
                                    </td>
                                </tr>
                                <tr>
                                    <th className="border border-dark border-right rounded">
                                        entries()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Returns an Iterator with the [value,value] pairs from a Set
                                    </td>
                                </tr>
                                <tr>
                                    <th className="border border-dark border-right rounded">
                                        
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                    
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="col" className="border border-dark border-right rounded">
                                    Property
                                    </th>
                                    <th scope="col" className="border border-dark border-right rounded">
                                    Description
                                    </th>
                                </tr>
                                <tr>
                                    <th className="border border-dark border-right rounded">
                                        size
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Returns the number elements in a Set
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <h4 className="text-dark">How to Create a Set</h4>
                        <p><b>You can create a JavaScript Set by:</b></p>
                        <li>Passing an Array to new Set()</li>
                        <li>Create a new Set and use add() to add values</li>
                        <li>Create a new Set and use add() to add variables</li>
                        <hr/>
                        <h4 className="text-dark">Map()</h4>
                        <p>A Map holds key-value pairs where the keys can be any datatype.<br/>
                        A Map remembers the original insertion order of the keys.<br/>
                        A Map has a property that represents the size of the map.</p>
                        <table className="table text-dark">
                            <tbody>
                                <tr>
                                    <th scope="col" className="border border-dark border-right rounded">
                                        Method
                                    </th>
                                    <th scope="col" className="border border-dark border-right rounded">
                                    Description
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        new Map()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Creates a new Map object
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        set()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Sets the value for a key in a Map
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        get()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Gets the value for a key in a Map
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        clear()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Removes all the elements from a Map
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        delete()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Removes a Map element specified by a key
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        has()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Returns true if a key exists in a Map
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        forEach()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Invokes a callback for each key/value pair in a Map
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        entries()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Returns an iterator object with the [key, value] pairs in a Map
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        entries()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Returns an Iterator with the [value,value] pairs from a Set
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        entries()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Returns an Iterator with the [value,value] pairs from a Set
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        keys()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Returns an iterator object with the keys in a Map
                                    </td>
                                </tr>   <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        values()
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        eturns an iterator object of the values in a Map
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                    
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="col" className="border border-dark border-right rounded">
                                    Property
                                    </th>
                                    <th scope="col" className="border border-dark border-right rounded">
                                    Description
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        size
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Returns the number of Map elements
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                        <h4 className="text-dark">How to Create a Map</h4>
                        <p><b>You can create a JavaScript Map by:</b></p>
                        <li>Passing an Array to new Map()</li>
                        <li>Create a Map and use Map.set()</li>
                        <div >
                            <article>
                                <header>
                                </header>
                                <main>
                                <Markdown
                                    options={{
                                    overrides: {
                                        Code: {
                                        component: Code,
                                        props: {
                                            isDark,
                                            setIsDark
                                        }
                                        }
                                    }
                                    }}
                                >
                                    {postContent5}
                                </Markdown>
                                </main>
                            </article>
                        </div>

                        <div className=" pagination w-100">
                            <Routes>
                                <Route  path="/js1" element={ <JS1 />} />
                                <Route  path="/js3" element={ <JS3 />} />
                            </Routes>
                        </div>  
                        <Nav aria-label="Page navigation example pb-3">
                            <NavLink className="page-link text-dark rounded btn-primary p-2 mb-5 mx-4"  to="/js1"><b>Previous</b></NavLink>
                            <NavLink className="page-link text-dark rounded btn-primary p-2 mb-5"  to="/js3"><b>Next</b></NavLink>
                        </Nav>
                    </div>
                </div>
            </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
    )
}

export default JS2

