import React from "react";
import Comment from "../Common/Comment";
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import BackButton from '../Common/BackButton';
import BackToTopBtn from "../Common/BackToTopBtn";
import { Helmet } from 'react-helmet-async';
import InformationArchitecture from "./InformationArchitecture";
import DesignThinking from "./DesignThinking";
import DesignProcess from "./DesignProcess";

const UIUXDesign = () => {
   
  return (
    <div className="overflow-hidden">
        <Helmet>
          <link rel="icon" href="images/takawale.ico"/>
          <title>"What is UI/UX design?</title>
          <meta name="description" content="What is UI/UX design?"/>
          <meta name="keywords" content='"What is UI/UX design?'/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
        <div className="container">
            <div className="row">
            <div className="col-12 text-center">
                <h3 className="text-white font-tertiary pt-2">UI/UX design</h3>
            </div>
            </div>
        </div>
        
        
        </section>


        <section className="section mx-2">
        <div className="bg-blur rounded">
            <div className="row  p-3">
            <div className="col-lg-12">
                
                <p className="px-4">Published by <span className="font-Croissant">takawale.com</span></p>
                <div className="content rounded">
                <embed src="images/blog/post-2.jpg" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-75" />
                <h4 className="text-dark">What is UI/UX design?</h4>
                <p>The UI & UX Design are two different process, help to win the consumers confidence and make them use your application or website providing them what they are looking for. The number of consumers you are getting on your website or application can measure the success of the great UI and UX process you adapt while designing an application.</p>
                
                <h4 className="text-dark">UX Design</h4>
                <p>The effectiveness of an internet app or website depends on one key factor - the user experience. Customer satisfaction is everything. UX design process is the process adapted at the very begining of any website or application development. UX design is the process which focuses on integrating an application or website with its branding, design, usability, and performance.
                <p>It is also a broad umbrella term divided into four main disciplines: </p>

                    <p> <h5 class="text-dark">1. Experience Strategy (ExS)</h5>
                        Experience Strategy, or ExS, is an area of UX that involves incorporating business, technical, and design strategies into solutions that will bring value to the user using the product as well as the company providing the product.</p>   

                    <p> <h5 class="text-dark">2. Interaction Design (IxD)</h5>
                        Design is essentially the practice of designing interactive digital products, environments, systems, and services. It focuses on creating a flow where a user can locate and manipulate information quickly and in a succinct manner.</p>  

                    <p> <h5 class="text-dark">3. User Research (UR)</h5>
                        User research focuses on understanding user behaviors, needs, and motivations through observation techniques, task analysis, and other feedback methodologies.  It is “the process of understanding the impact of design on an audience.</p> 

                    <p> <h5 class="text-dark">4. Information Architecture (IA)</h5>
                        Is a science of organizing and structuring content of the websites, web and mobile applications, and social media software. <br/>Information architecture aims at organizing content so that users would easily adjust to the functionality of the product and could find everything they need without big effort.</p> 

                    <p>UX designers focus on every aspect of the user experience, whether it's pleasure, efficiency, or fun. They also pay close attention to the language used and confirm that it's both convincing and consistent. Consequently, there is no correct definition of a good user experience. </p>

                <embed src="images/blog/pasted-image-0-1.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4" /></p>

                <h4 className="text-dark">UI Design</h4>
                <p>UI Design is all about the structure of the website/application, user manipulation and communication. This is one of the reasons it is essential to pay attention to UI. It is the basic building block of how your website is configured and how it functions when visited by your target audience.</p>
                <p>Designs of buttons, lists, toggles, icons, breadcrumbs, tags, etc can impact the usability of a website or app.</p>
                <p>It is the way you design your website from the back-end so that end-users can have a seamless and enjoyable experience. It is surprising to see how big of an impact even the most minor adjustments in UI design can have.</p>
                
                <h4 className="text-dark">Conclusion</h4>
                <p>Finally the purpose for UI/UX design is to design any application or website, the goal of a UI/UX designer involved in the design should thinking of the 'Why' before determining the 'What' (of a feature to be implemented). After answering this, the 'How' should be tackled to create a product that offers meaningful user experiences to create an effective website or an effective application.
                </p>
                </div>
            </div>
            </div>
        </div>
        </section>
    
        <section>
            <Comment/>
        </section>

        <section className="section mb-200 mx-2">
        <div className="bg-blur rounded px-3">
            <div className="row">
            <div className="col-12 text-center">
                <h2 className="text-primary text-uppercase rounded">Similar Posts</h2>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/post-4.jpg" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">Design Process
                    </h4>
                    <p className="cars-text ">The design process is a tool that helps you break down large projects into smaller, easier-to handle stages. It's prominent in engineering, architecture, and manufacturing because it helps companies deliver finished solutions that customers want and need.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/designprocess"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/designprocess" element={ <DesignProcess />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/post-5.jpg" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">Design Thinking
                    </h4>
                    <p className="cars-text">Design thinking is a non-linear, iterative process that teams use to understand users, challenge assumptions, redefine problems and create innovative solutions to prototype and test. Involving five phases—Empathize, Define, Ideate, Prototype and Test—it is most useful to tackle problems that are ill-defined or unknown.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/designthinking"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/designthinking" element={ <DesignThinking />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/post-6.jpg" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">What is Information Architecture?
                    </h4>
                    <p className="cars-text">When creating a digital product, you have to structure your content and functionality in a way that users understand. Users should be able to quickly and easily find the information they’re looking for — the practice of organizing content this way is called information architecture. <br/>Take a deep dive into information architecture.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/informationarchitecture"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/informationarchitecture" element={ <InformationArchitecture />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            </div>
            <div className="row pt-3">
                <BackButton/>
            </div>
        </div>
        </section>
        <BackToTopBtn/>
        <Footer/>    
    </div>
    )
}

export default UIUXDesign

