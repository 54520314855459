import React from 'react';
import BlogTabs from './BlogTabs';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import BackToTopBtn from "../Common/BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const Portfolio = () => {
  return (
    <div>
      <Helmet>
        <link rel="icon" href="images/takawale.ico"/>
        <title>Blogs | All About Web Technology & Digital Designs</title>
        <meta name="description" content="Freelancer specializing in web design and development, graphic design, and UI/UX services. I deliver innovative, user-friendly digital solutions that enhance your brand and engage users. Let’s work together to create something exceptional!"/>
        <meta name="keywords" content='web design services, crafting visually stunning and user-friendly websites, custom layouts, responsive design'/>
      </Helmet>
      <Header/>
      <section classNameName="heading-area" id="parallax">
      </section>

      <section className="mb-200">
        <div className="bg-primary position-relative">
        <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                  <h3 className="text-white font-tertiary pt-2">Blog</h3>
              </div>
            </div>
        </div>
        </div>
        <BlogTabs />
      </section> 
      <BackToTopBtn />
      <Footer/>
    </div>
  )
}

export default Portfolio