import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import BackToTopBtn from '../Common/BackToTopBtn';
import BackButton from '../Common/BackButton';
import { Helmet } from 'react-helmet-async';

const HospitalPlus = () => {
  return (
      <div>
        <Helmet>
          <link rel="icon" href="images/takawale.ico"/>
          <title>hospital management app</title>
          <meta name="description" content="Info of hospital management app"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
        <div className="container">
            <div className="row">
            <div className="col-12 text-center">
              <h3 className="text-white font-tertiary pt-2">hospital+(hospital management app)</h3>
            </div>
            </div>
        </div>
        
        
        </section>


        <section className="section mb-200 mx-2">
          <div className="bg-blur rounded">
            <div className="row p-3">
              <div className="col-lg-12">
                <div className="content p-3 rounded"> 
                  <h4 className="text-dark text-center"><b>Responsive Web-App. Design, and Web-App. Development</b></h4>
                  <embed src="images/portfolio/hospitalplus.png" alt="post-thumb" className="img-fluid p-3 w-100" />
                  <p>hospital+ is a web app designed and developed for managing hospital's daily activities. It's costume designed as per customer requirment. </p>
                  <p>We at PST designed and developed this app. I was a part of design team for this app. This app. makes the hospital management easy with the modules it contains. hospital+ app. includes modules mentioned below.
                    <li>O.T MGMT(Opration Theater management)</li>
                    <li>Staff MGMT</li>
                    <li>Patient MGMT</li>
                    <li>Supply MGMT</li>
                    <li>Billing MGMT</li>
                    <li>Reports</li>
                  </p>
                  
                  <h4 className="text-dark">Why Client Need this App.?</h4>
                  <li>Make management of the hospital easy.</li>
                  <li>hospital+ app. consists of different modules dedicated to manage and maintain daily activities of different departments in the hospital easy</li>
                  <li>This App. Manage
                    <p>
                    <ol>1 Patient Appoinment.</ol>
                    <ol>2 Docters and Other staff info.</ol>
                    <ol>3 Patient info along with patient treatment and medical history.</ol>
                    <ol>4 Assigning duties to the hospital staff.</ol>
                    <ol>5 Bill generation.</ol>
                    <ol>6 Supply chain managenent of the required medicine stock of the hospital.</ol>
                    <ol>7 Kepp track of purchase and maintainence of medical equipments.</ol>
                    <ol>8 Operation Theater booking.</ol>
                    <ol>9 Generate reports and provide info. of available rooms, admited and discharged patients, doctor and staff availability, daily and monthely revinue collection etc.</ol>
                    <ol>10 Provide representation of Reports in the form of graph and charts.</ol>
                    </p>
                  </li>
                  <li>The app. provides user friendly deign that provides diffrent pages to manage different processes performed in the hospital.</li>
                  <h4 className="text-dark">Create an app. that works only on specific devices</h4>
                  <p>We aim to create a well-thought-out responsive web app. that works only on specific devices like Desktop, laptop, and tablet.</p>

                  <h4 className="text-dark">Impress With Efficient UI/UX Design</h4>
                  <p>We strive hard to provide stunning UI/UX solution with simple yet effective web app. design. for the managing the hospital.</p>

                  <h4 className="text-dark">Deliver User-Friendly Designs</h4>
                  <p>We provide functional web designs which user can easily understand and use to get the information he need from this website.</p>
                  <embed src="images/portfolio/hospital+/Dashboard.png" alt="post-thumb" className="img-fluid mx-auto d-block p-3 h-25 w-75 " />

                  <h4 className="text-dark">Build customised, user friendly and cost effective web solutions.</h4>
                  <p>Our goal is to create a functional and user-friendly web app. which help user manage his business and provide easy solution to optimise his business operations.</p>
                  <embed src="images/portfolio/hospital+/O.T Booking.png" alt="post-thumb" className="img-fluid mx-auto d-block p-3 h-25 w-75 " />
                  <div className="row pt-3">
                    <BackButton/>
                  </div>
                  </div>
                  
              </div>
            </div>
          </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
  )
}

export default HospitalPlus
