import React from "react";
import Header from '../Common/Header';
import WSTabs from './WSTabs';
import Footer from '../Common/Footer';
import BackToTopBtn from "../Common/BackToTopBtn";
import { Helmet } from 'react-helmet-async';

function WebStudio() {
   
  return (
    <div>
        <Helmet>
            <link rel="icon" href="images/takawale.ico"/>
            <title>Web-Templates | Get web templates customized as per your needs</title>
            <meta name="description" content="Freelancer specializing in web design and development, graphic design, and UI/UX services. I deliver innovative, user-friendly digital solutions that enhance your brand and engage users. Let’s work together to create something exceptional!"/>
            <meta name="keywords" content='web design and development services, crafting visually stunning and user-friendly websites, custom layouts, responsive design, UI/UX Designer, Javascript Developer, Graphic Design'/>
        </Helmet>
        <Header/>
        <section className=" bg-primary">
            <div className="container">
                <div className="row">
                <div className="col-12 text-center">
                    <h3 className="text-white font-tertiary pt-2">Web-Templates</h3>
                </div>
                </div>
            </div>
        </section>
        <section className="section pt-4 mb-200">
            <div className="bg-blur px-3 rounded shadow-lg mx-4">
                <div className="row justify-content-around px-3 pt-3 rounded">
                    <div className="col-lg-12 pt-3">
                        <p className="fs-1 font-Bellefair text-center xbootstrap rounded text-dark border-bottom border-dark">Get web templates customized as per your needs</p>
                    </div>
                </div>
                <WSTabs/>
            </div>
        </section>
        <BackToTopBtn />
        <Footer/>
    </div>
  )
}

export default WebStudio