import { useState, useEffect } from "react";
import Nav from 'react-bootstrap/Nav';
import { Routes, NavLink, Route} from "react-router-dom";
import Markdown from "markdown-to-jsx";	
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import Code from "../../Common/Code";
import JS2 from './JS2';
import JSSidebar from '../JSSidebar';
import BackToTopBtn from "../../Common/BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const JS1 = () => { 
    const [postContent0, setPostcontent0] = useState('');
    const [postContent1, setPostcontent1] = useState('');
    const [postContent2, setPostcontent2] = useState('');
    const [postContent3, setPostcontent3] = useState('');
    const [postContent4, setPostcontent4] = useState('');
    const [postContent5, setPostcontent5] = useState('');
    const [isDark, setIsDark] = useState(true);

    useEffect(() => {
       
        import('./../JS-Tutorials/Tutorial0.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent0(response))
            .catch(err => console.log(err))
        )
          import('./../JS-Tutorials/Tutorial1.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent1(response))
              .catch(err => console.log(err))
          )
          import('./../JS-Tutorials/Tutorial2.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent2(response))
              .catch(err => console.log(err))
          )
          import('./../JS-Tutorials/Tutorial3.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent3(response))
              .catch(err => console.log(err))
          )
          import('./../JS-Tutorials/Tutorial4.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent4(response))
              .catch(err => console.log(err))
          )
          import('./../JS-Tutorials/Tutorial5.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent5(response))
              .catch(err => console.log(err))
          )
      }, [])

  return (
    <div className="overflow-hidden">
        <Helmet>
            <link rel="icon" href="images/takawale.ico"/>
            <title>Javascript | JS CheatSheet I</title>
            <meta name="description" content="JavaScript CheatSheet, contains all that JavaScript developer must know"/>
            <meta name="keywords" content="JavaScript CheatSheet, contains all that JavaScript developer must know"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
            <div className="container">
                <div className="row overflow-hidden">
                <div className="col-12 text-center">
                    <h3 className="text-white font-tertiary pt-2">JS CheatSheet</h3>
                </div>
                </div>
            </div>
        </section>

        <div className=" pagination w-100 mt-3">
            <Routes>
                <Route  path="/js2" element={ <JS2 />} />
            </Routes>
        </div>  
        <Nav aria-label="Page navigation example">
            <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/js2"><b>Next</b></NavLink>
        </Nav>
        <section className="section mb-200 mx-2">
            <div className="bg-blur rounded">
                <div className="row p-3">
                    <div className="col-lg-12">
                        
                        <p className="text-dark px-4">Published by <span className="text-dark font-Croissant">takawale.com</span></p>
                        <div className="content rounded">
                        <embed src="images/blog/js.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-100" />
                        <div>
                            <JSSidebar/>
                        </div>
                        <h4 className="text-dark pt-5">Introduction</h4>
                        <p>JavaScript is the world's most popular programming language.<br/>
                        JavaScript is the programming language of the Web.<br/>
                        JavaScript is a lightweight, interpreted programming language.</p>
                        
                        <h4 className="text-dark">Variables</h4>
                        <p>Variables in JavaScript are containers for storing data.<br/>
                        In JavaScript you usage variables in the following three ways:</p>
                        <table className="table  text-dark">
                            <tbody className="px-5">
                                <tr>
                                    <th scope="col" className="border border-dark border-right rounded">
                                        Variables
                                    </th>
                                    <th scope="col" className="border border-dark border-right rounded w-50">
                                    Description
                                    </th>
                                    <th scope="col" className="border border-dark border-right rounded">
                                        Example
                                    </th>
                                </tr>
                                <tr>
                                    <th className="border border-dark border-right rounded">
                                        var
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        var is the most commonly used variable in JavaScript. It can be initialized to a value, redeclared and its value can be reassigned, but only inside the context of a function. It can be function scoped or globally scoped.
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        var x= value;
                                    </td>
                                </tr>
                                <tr>
                                    <th className="border border-dark border-right rounded">
                                        let
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        let in JavaScript is similar to var only difference lies in the scope. var is function scoped, let is block scoped. It cannot be redeclared but can be reassigned a value.
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        let y= value;
                                    </td>
                                </tr>
                                <tr>
                                    <th className="border border-dark border-right rounded">
                                        const
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        const in JavaScript is used to declare a fixed value that cannot be changed over time once declared. They can neither be redeclared nor be reassigned. They cannot be hoisted either.
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        const z= value;
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="p-2 rounded">
                        <article>
                            <header>
                            </header>
                            <main> 
                            <Markdown
                                options={{
                                overrides: {
                                    Code: {
                                    component: Code,
                                    props: {
                                        isDark,
                                        setIsDark
                                    }
                                    }
                                }
                                }}
                            >
                                {postContent0}
                            </Markdown>
                            </main>
                        </article>
                        </div>
                        
                        <h4 className="text-dark">Datatypes</h4>
                        <p>There are following primitive and non-primitive datatypes in JavaScript:</p>
                        <table className="table  text-dark">
                            <tbody>
                                <tr>
                                    <th scope="col" className="border border-dark border-right rounded w-25">
                                        Datatypes
                                    </th>
                                    <th scope="col" className="border border-dark border-right rounded w-50">
                                    Description
                                    </th>
                                    <th className="border border-dark border-right rounded">
                                    Example
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        Number
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        The Number object represents numerical date, either integers or floating-point numbers. 
                                        In the place of number, if you provide any non-number argument, then the argument cannot be converted into a number, it returns NaN (Not-a-Number).
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        var x= number;
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        String
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        It is of series of multiple characters written within double or single quotes.
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        var x= “characters”;
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        Boolean
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        It can have only two values true or false.
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        var x= true/false;
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        Null
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Null is an object in JavaScript and represents primitive data types. A null value in JavaScript is used for referring absence of any object value and if any function or variable returns null, then we can infer that the object could not be created. If we pass null as the default parameter to any function type it would take the ‘null’ as a value passed to it.
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        var x= null;
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        Undefined
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        It represents that the variable is declared but not assigned any value. A variable can also be emptied by setting the value to undefined.
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        let x; / let x= undefined;
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        Object
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        It is a complex data type that allows us to store a collection of data. It contains properties defined as key-value pair.
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        let person = "John Doe";<br/>
                                        let person = &#65371;firstName:"John", lastName:"Doe", age:50, eyeColor:"blue"&#65373;;
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        Array
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        It is a datatype used to store multiple values in a single variable. each value(element) has a numeric position(index) which starts from 0 , and it may contain data of any data type and even other arrays.
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    var fruits = new Array( "apple", "orange", "mango" );
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                        
                        <div className="p-2 rounded">
                        <article>
                            <header>
                            </header>
                            <main> 
                            <Markdown
                                options={{
                                overrides: {
                                    Code: {
                                    component: Code,
                                    props: {
                                        isDark,
                                        setIsDark
                                    }
                                    }
                                }
                                }}
                            >
                                {postContent1}
                            </Markdown>
                            </main>
                        </article>
                        </div>

                        <h4 className="text-dark">Arrays Methods</h4>
                        <div className="p-2 rounded">
                        <article>
                            <header>
                            </header>
                            <main> 
                            <Markdown
                                options={{
                                overrides: {
                                    Code: {
                                    component: Code,
                                    props: {
                                        isDark,
                                        setIsDark
                                    }
                                    }
                                }
                                }}
                            >
                                {postContent2}
                            </Markdown>
                            </main>
                        </article>
                        </div>

                        <h4 className="text-dark">Operators</h4>
                        <p>JavaScript operators are symbols used to perform various operations on variables (operands). Following are the different types of operators:</p>
                        <table className="table  text-dark">
                            <tbody>
                                <tr>
                                    <th scope="col" className="border border-dark border-right rounded w-25">
                                        Operators
                                    </th>
                                    <th scope="col" className="border border-dark border-right rounded w-50">
                                    Description
                                    </th>
                                    <th className="border border-dark border-right rounded">
                                    Symbols
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        Arithmetic
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Arithmetic operators are used to perform basic arithmetic operations like addition, subtraction, multiplication, division, modulus, increment, and decrement on the variables(operands).
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        +,<br/>-,<br/>*,<br/>/,<br/>%,<br/>++,<br/>–
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        Comparison
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        The JavaScript comparison operator compares the two operands as equal, not equal, identical, greater than, less than, greater than equal to, less than equal to.
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        ==, <br/>===,<br/>!=,<br/>&#8250;,<br/>&#8249;,<br/>&#8250;=,<br/>&#8249;=
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        Bitwise
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        The bitwise operators perform bitwise operations like bitwise OR, bitwise AND, XOR, NOT, right shift, and left shift on variables(operands).
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        &,<br/> | ,<br/> ^,<br/>~&#8249; &#8249;,<br/> &#8250; &#8250;, <br/>&#8250; &#8250; &#8250;
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        Logical
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        There are three logical operators in javascript.
                                        <li><b>logical AND:</b> When all the operands are true.</li>
                                        <li><b>logical OR:</b> When one or more than one operands are true.</li>
                                        <li><b>logical NOT:</b> Converts true to false.</li>
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    logical AND- &&, <br/>logical OR- ||, <br/>logical NOT- !
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        Assignment
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        Assignment operators are used to assign values to variables. <br/>For example- 
                                        const number = 3 + 5; &#8260; &#8260; 8
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        =,<br/> +=,<br/>-=,<br/>*=,<br/>/=,<br/>%=
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        Ternary
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        The “Question mark” or “conditional” operator in JavaScript is a ternary operator that has three operands. It is the simplified operator of if/else.
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        condition ? value if true : value if false
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="border border-dark border-right rounded">
                                        Nullish coalescing operator
                                    </th>
                                    <td className="border border-dark border-right rounded">
                                        The null coalescing operator allows for the selection of the first non-null value from a pair of values. It is used to set the default value for variables of nullable value type or reference type.
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        (null || undefined) ?? "foo"; // returns "foo"
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div >
                        <article>
                            <header>
                            </header>
                            <main>
                            <Markdown
                                options={{
                                overrides: {
                                    Code: {
                                    component: Code,
                                    props: {
                                        isDark,
                                        setIsDark
                                    }
                                    }
                                }
                                }}
                            >
                                {postContent3}
                            </Markdown>
                            </main>
                        </article>
                        </div>
                        <div className="p-2 rounded">
                        <article>
                            <header>
                            </header>
                            <main> 
                            <Markdown
                                options={{
                                overrides: {
                                    Code: {
                                    component: Code,
                                    props: {
                                        isDark,
                                        setIsDark
                                    }
                                    }
                                }
                                }}
                            >
                                {postContent4}
                            </Markdown>
                            </main>
                        </article>
                        </div>

                        <h4 className="text-dark">Conditional Statements</h4>
                        <p>Very often when you write code, you want to perform different actions for different decisions.
                        You can use conditional statements in your code to do this
                        In JavaScript we have the following conditional statements:</p>
                        <li>Use <b>if </b>to specify a block of code to be executed, if a specified condition is true.</li>
                        <li>Use <b>else</b> to specify a block of code to be executed, if the same condition is false.</li>
                        <li>Use <b>else if</b> to specify a new condition to test, if the first condition is false.</li>
                        <li>Use <b>switch</b> to specify many alternative blocks of code to be executed.</li>
                        
                        <div >
                        <article>
                            <header>
                            </header>
                            <main>
                            <Markdown
                                options={{
                                overrides: {
                                    Code: {
                                    component: Code,
                                    props: {
                                        isDark,
                                        setIsDark
                                    }
                                    }
                                }
                                }}
                            >
                                {postContent5}
                            </Markdown>
                            </main>
                        </article>
                        </div>

                        <div className=" pagination w-100">
                            <Routes>
                                <Route  path="/js2" element={ <JS2 />} />
                            </Routes>
                        </div>  
                            <Nav aria-label="Page navigation example">
                                <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/js2"><b>Next</b></NavLink>
                            </Nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
    )
}

export default JS1

