import React, {useState, useEffect} from "react";
import Nav from 'react-bootstrap/Nav';
import { Routes, NavLink, Route} from "react-router-dom";
import Markdown from "markdown-to-jsx";	
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import Code from "../../Common/Code";
import RJS7 from './RJS7';
import Sidebar from "../Sidebar";
import BackToTopBtn from "../../Common/BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const RJS8= () => {
    const [postContent0, setPostcontent0] = useState('');
    const [isDark, setIsDark] = useState(true);

    useEffect(() => {
       
        import('./../ReactJS-Tutorials/Tutorial22.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent0(response))
            .catch(err => console.log(err))
        )
      }, [])
   
  return (
    <div className="overflow-hidden">
        <Helmet>
            <link rel="icon" href="images/takawale.ico"/>
            <title>React Js Blog Info IX</title>
            <meta name="description" content="ReactJs Tutorial Blog"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
            <div className="container">
                <div className="row">
                <div className="col-12 text-center">
                    <h3 className="text-white font-tertiary pt-2">ReactJS</h3>
                </div>
                </div>
            </div>
        </section>
        <div className=" pagination w-100 mt-3">
                <Routes>
                    <Route  path="/rjs7" element={ <RJS7/>} />
                </Routes>
            </div>  
            <Nav aria-label="Page navigation example">
                <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/rjs7"><b>Previous</b></NavLink>
            </Nav>
        <section className="section mb-200 mx-2">
            <div className="bg-blur rounded">
                <div className="row p-3">
                    <div className="col-lg-12">
                        
                        <p className="text-darkpx-4">Published by <span className="text-dark font-Croissant">takawale.com</span></p>
                        <div className="content rounded">
                            <embed src="images/blog/ReactJS.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-100" />
                            <div>
                                <Sidebar/>
                            </div>
                            <h4 className="text-dark pt-5">React Router</h4>
                            <li>Routing is a process in which a user is directed to different pages based on their action or request</li>
                            <li>ReactJS Router is mainly used for developing Single Page Web Applications.</li>
                            <li>React Router is used to define multiple routes in the application.<br/> 
                            When a user types a specific URL into the browser, and if this URL path matches any 'route' inside the router file, the user will be redirected to that particular route.</li>
                            <li>React Router is a standard library system built on top of the React and used to create routing in the React application using React Router Package.</li>
                            <li>It provides the synchronous URL on the browser with data that will be displayed on the web page.</li>
                            <li>It maintains the standard structure and behavior of the application and mainly used for developing single page web applications.</li>

                            <h4 className="text-dark">Need of React Router</h4>
                            <p>React Router plays an important role to display multiple views in a single page application.<br/> 
                            Without React Router, it is not possible to display multiple views in React applications.<br/> 
                            Most of the social media websites like Facebook, Instagram uses React Router for rendering multiple views.</p>

                            <h4 className="text-dark">React Router Installation</h4>
                            <p><b>React contains three different packages for routing. These are:</b></p>
                            <p><b>react-router:</b> It provides the core routing components and functions for the React Router applications.</p>
                            <p><b>react-router-native:</b> It is used for mobile applications.</p>
                            <p><b>react-router-dom:</b> It is used for web applications design.</p>
                            <p>It is not possible to install react-router directly in your application.<br/> 
                            To use react routing, first, you need to install react-router-dom modules in your application.<br/> 
                            The below command is used to install react router dom.</p>

                            <div className="border border-dark bg-light p-4 rounded text-dark">
                                <b>
                                $ npm install react-router-dom --save 
                                </b>
                            </div>

                            <h4 className="text-dark">Setup React Router</h4>
                            <p>To configure React router, navigate to the index.js file, which is the root file, and import BrowserRouter from the react-router-dom package that we installed, wrapping it around our App component as follows:</p>
                            <div className="border border-dark bg-light p-4 rounded text-dark">
                                <b>
                               <h5>index.js</h5>
                                import React from 'react';<br/>
                                import ReactDOM from 'react-dom/client';<br/>
                                import  &#65371; BrowserRouter &#65310; from 'react-router-dom';<br/>
                                import App from './App';<br/>
                                <br/>
                                const root = ReactDOM.createRoot(document.getElementById('root'));<br/>
                                root.render(<br/>
                                &#65308;React.StrictMode&#65310;<br/>
                                    &#65308;BrowserRouter&#65310;<br/>
                                    &#65308;App /&#65310;<br/>
                                    &#65308;/BrowserRouter&#65310;<br/>
                                &#65308;/React.StrictMode&#65310;<br/>
                                );<br/>
                                </b>
                            </div>

                            <h4 className="text-dark">Configure Routes In React</h4>
                            <p>We would first create those components, in our case three pages: the Home page, the About Page, and the Products Page. <br/>
                            we can now set up and configure our routes in the App.js file, which serves as the foundation for our React application:</p>

                            <div className="border border-dark bg-light p-4 rounded text-dark">
                                <b>
                               <h5> App.js</h5><br/>
                                import &#65371; Routes, Route  &#65373; from 'react-router-dom';<br/>
                                import Home from './Pages/Home';<br/>
                                import About from './Pages/About';<br/>
                                import Products from './Pages/Products';<br/>
                                const App = () =&#65310; &#65371;<br/>
                                return (&#65310;<br/>
                                    &#65308;&#65310;<br/>
                                    &#65308;Routes&#65310;<br/>
                                        &#65308;Route path="/" element=&#65371; &#65308;Home /&#65310; &#65373; /&#65310;<br/>
                                        &#65308;Route path="/products" element=&#65371; &#65308;Products /&#65310; &#65373; /&#65310;<br/>
                                        &#65308;Route path="/about" element=&#65371; &#65308;About /&#65310; &#65373; /&#65310;<br/>
                                    &#65308;/Routes&#65310;<br/>
                                    &#65308;/&#65310;<br/>
                                );<br/>
                                &#65373;;<br/>
                                export default App;
                                </b>
                            </div>
                            <p>We can see in the above code that we imported Routes and Route components from react-router-dom and then used them to declare the routes we want.<br/> 
                            All Routes are wrapped in the Routes tag, and these Routes have two major properties:</p>
                            <li><b> path: </b> As the name implies, this identifies the path we want users to take to reach the set component. <br/>
                            When we set the path to /about, for example, when the user adds /about to the URL link, it navigates to that page.</li>
                            <li><b>element:</b> This contains the component that we want the set path to load. <br/>
                            This is simple to understand, but remember to import any components we are using here, or else an error will occur.</li>

                            <h4 className="text-dark">Configured Routes with Links</h4>
                            <p>Just like <b>a tag</b> in HTML we use <b>Link tag</b> in ReactJS to navigate to different pages or components.<br/>
                            We made a NavBar component and placed it at the top of our routes in the App.js file so that it appears regardless of the route:</p>

                            <div className="border border-dark bg-light p-4 rounded text-dark">
                                <b>
                               <h5> App.js</h5><br/>
                               import NavBar from './Components/NavBar';<br/>
                               <br/>
                                const App = () =&#65310; &#65371;<br/>
                                return (<br/>
                                    &#65308;&#65310;<br/>
                                    &#65308;NavBar /&#65310;<br/>
                                    &#65308;Routes&#65310;<br/>
                                    &#8260;&#8260; ...<br/>
                                        &#65308;/Routes&#65310;<br/>
                                        &#65308;/&#65310;<br/>
                                );<br/>
                                &#65373;;<br/>
                                export default App;
                                </b>
                            </div>

                            <p><b>Let’s now add Links to the NavBar this way:</b></p>
                            <div className="border border-dark bg-light p-4 rounded text-dark">
                                <b>
                               <h5> Components/NavBar.js</h5><br/>
                               import &#65371; Link &#65373; from 'react-router-dom';<br/>
                               <br/>
                                const NavBar = () =&#65310; &#65371;<br/>
                                return (<br/>
                                &#65308;nav&#65310;<br/><br/>
                                    &#65308;ul&#65310;<br/><br/>
                                        &#65308;li&#65310;<br/>
                                            &#65308;Link to="/"&#65310;Home&#65308;/Link&#65310;<br/>
                                        &#65308;/li&#65310;<br/><br/>
                                        &#65308;li&#65310;<br/>
                                            &#65308;Link to="/about"&#65310;About&#65308;/Link&#65310;<br/>
                                        &#65308;/li&#65310;<br/><br/>
                                        &#65308;li&#65310;<br/>
                                            &#65308;Link to="/products"&#65310;Products&#65308;/Link&#65310;<br/>
                                        &#65308;/li&#65310;<br/><br/>
                                    &#65308;/ul&#65310;<br/><br/>
                                &#65308;/nav&#65310;<br/>
                                );<br/>
                                &#65373;;<br/>
                                <br/>
                                export default NavBar;
                                </b>
                            </div>
                            <p>In the above code, we first imported <b>Link</b> from <b>react-router-dom </b> and then added the <b>to </b> property based on the <b>path</b> we specified while configuring our routes. <br/>
                            This is how simple it is to add links to our React application, allowing us to access configured routes.</p>
                            
                            <h4 className="text-dark">Implement Active Links</h4>
                            <p>It is easier to handle and implement the active link feature in React by using the <b>NavLink</b> component rather than the <b>Link</b> component, which knows whether a user is currently navigated to by adding a <b>class</b> of <b>active</b> to such link.</p>
                            <p>The <b>NavLink</b> component will now replace the <b>Link</b> component in the <b>NavBar:</b></p>
                            <div className="border border-dark bg-light p-4 rounded text-dark">
                                <b>
                               <h5>NavBar.js</h5><br/>
                               import  &#65371; NavLink &#65373; from 'react-router-dom';<br/>
                               <br/>
                               const NavBar = () =&#65310;  &#65371;<br/>
                                return (<br/>
                                    &#65308;nav&#65310;<br/>
                                    &#65308;ul&#65310;<br/><br/>
                                    &#65308;li&#65310;<br/>
                                    &#65308;NavLink to="/"&#65310;Home&#65308;/NavLink&#65310;<br/>
                                    &#65308;/li&#65310;<br/><br/>
                                    &#65308;li&#65310;
                                    &#65308;NavLink to="/about"&#65310;About&#65308;/NavLink&#65310;<br/>
                                    &#65308;/li&#65310;<br/><br/>
                                    &#65308;li&#65310;<br/>
                                    &#65308;NavLink to="/products"&#65310;Products&#65308;/NavLink&#65310;<br/>
                                    &#65308;/li&#65310;<br/><br/>
                                    &#65308;/ul&#65310;<br/>
                                    &#65308;/nav&#65310;<br/>
                                );<br/>
                                &#65373;;<br/>
                                <br/>
                                export default NavBar;
                                </b>
                            </div>

                            <p>This alone does not make a significant difference in the user interface until we style the active class added, which can be done however you wish, but here is an example of the styling I used:</p>
                            <div className="border border-dark bg-light p-4 rounded text-dark">
                                <b>
                               <h5>index.css</h5><br/>
                               ul li a &#65371;<br/>
                                color: #000;<br/>
                                &#65373;<br/>
                                <br/>
                                ul li a:hover &#65371;<br/>
                                color: #00a8ff;<br/>
                                &#65373;<br/>
                                <br/>
                                ul li a.active &#65371;<br/>
                                color: #00a8ff;<br/>
                                &#65373;
                                </b>
                            </div>

                            <h4 className="text-dark">Fix No Routes Found Error</h4>
                            <p>When routing, a situation may cause a user to access an unconfigured route or a route that does not exist; when this occurs, React does not display anything on the screen except a warning with the message "No routes matched location."</p> 
                            <p>This can be fixed by configuring a new route to return a specific component when a user navigates to an unconfigured route as follows:</p>
                            <div className="border border-dark bg-light p-4 rounded text-dark">
                                <b>
                                <h5>App.js</h5><br/>
                                import &#65371; Routes, Route &#65373; from 'react-router-dom';<br/>
                                import NoMatch from './Components/NoMatch';<br/>
                                <br/>
                                const App = () =&#65310; &#65371;<br/>
                                return (<br/>
                                    &#65308;&#65310;<br/>
                                    &#65308;Routes&#65310;<br/>
                                    &#8260;&#8260; ...<br/>
                                        &#65308;Route path="*" element=&#65371;&#65308;NoMatch /&#65310;&#65373; /&#65310;<br/>
                                    &#65308;/Routes&#65310;
                                    &#65308;/&#65310;<br/>
                                );<br/>
                                &#65373;;<br/>
                                <br/>
                                export default App;
                                </b>
                            </div>
                            <p>In the preceding code, we created a route with the path * to get all non-configured paths and assign them to the attached component.</p>

                            <h4 className="text-dark">Navigate Programmatically in React</h4>
                            <p>Programmatic navigation is the process of navigating/redirecting a user as a result of an action on a route, such as a login or a signup action, order success, or when he clicks on a back button.</p>
                            <p>Let's first look at how we can redirect to a page when an action occurs, such as when a button is clicked.<br/>
                            We accomplish this by adding an <b>onClick</b> event, but first, we must create the route in our <b>App.js</b> file. <br/>
                            After that, we can import the <b>useNavigate</b> hook from the <b>react-router-dom</b> and use it to navigate programmatically as follows:</p>
                            <div className="border border-dark bg-light p-4 rounded text-dark">
                            <b>
                            <h5>Products.js</h5><br/>
                            import &#65371; useNavigate &#65373; from 'react-router-dom';<br/>
                            <br/>
                            const Products = () =&#65310; &#65371;<br/>
                            const navigate = useNavigate();<br/>
                            return (<br/>
                                &#65308;div className="container"&#65310;<br/>
                                &#65308;div className="title"&#65310;<br/><br/>
                                &#65308;h1&#65310;Order Product CockTails&#65308;/h1&#65310;<br/><br/>
                                &#65308;/div&#65310;<br/>
                                &#65308;button className="btn" onClick=&#65371;() =&#65310; navigate('order-summary')&#65373;&#65310;<br/>
                                    Place Order<br/>
                                &#65308;/button&#65310;<br/>
                                &#65308;/div&#65310;<br/>
                            );<br/>
                            &#65373;;<br/>
                            <br/>
                            export default Products;
                            </b>
                            </div>
                            <p><b>Note:</b> We already created a route with the path <b>order-summary,</b> so when this button is clicked, the user is automatically navigated to the <b>orderSummary</b> component attached to this route.<br/>
                            We can also use this hook to handle the back button in the following manner:</p>
                            <div className="border border-dark bg-light p-4 rounded text-dark">
                            <b>
                            &#65308;button className="btn" onClick=&#65371;() =&#65310; navigate(-1)&#65373;&#65310;<br/><br/>
                            Go Back<br/><br/>
                            &#65308;/button&#65310; 
                            </b>
                            </div>

                            <h4 className="text-dark">Implement Lazy Loading with React Router</h4>
                            <p>Lazy loading is a technique in which components that are not required on the home page are not loaded until a user navigates to that page, allowing our application to load faster than having to wait for the entire app to load at once.<br/> 
                            This contributes to improved performance, which leads to a positive user experience.</p>
                            <p>To implement lazy loading, simply go to <b>App.js</b> and wrap our routes with the <b>Suspense</b> component, along with a <b>fallback</b> props that are rendered on the screen until the component loads:</p>
                            <div className="p-2 rounded">
                                <article>
                                    <header>
                                    </header>
                                    <main> 
                                    <Markdown
                                        options={{
                                        overrides: {
                                            Code: {
                                            component: Code,
                                            props: {
                                                isDark,
                                                setIsDark
                                            }
                                            }
                                        }
                                        }}
                                    >
                                        {postContent0}
                                    </Markdown>
                                    </main>
                                </article>
                            </div>

                            <p><b>Note:</b> We wrapped the routes with the Suspense component, and it’s important for you to know that the fallback props can hold a component.</p>

                        <div className=" pagination w-100">
                            <Routes>
                                <Route  path="/rjs7" element={ <RJS7/>} />
                            </Routes>
                        </div>  
                        <Nav aria-label="Page navigation example">
                            <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/rjs7"><b>Previous</b></NavLink>
                        </Nav>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
    )
}

export default RJS8
