import './App.css';
import './Post.css';
import './Code.css';
//import { UserContextProvider }   from "./Context/UserContext";
import { BrowserRouter , Route, Routes} from "react-router-dom";
import Home from './Component/Home';
import About from './Component/About';
import Blogs from './Component/Blogs/Blogs';
import Blog from './Component/Blogs/Blog';
import OurServices from './Component/OurServices';
import WebStudio from './Component/WebStudio/WebStudio';
import WS1 from './Component/WebStudio/WS1';
import WS2 from './Component/WebStudio/WS2';
import Portfolio from './Component/Portfolio/Portfolio';
import Contact from './Component/Contact';
import Blog1 from './Component/Blogs/Blog1';
import UserCenteredDesign from './Component/Blogs/UserCenteredDesign';
import UIUXDesign from './Component/Blogs/UIUXDesign';
import UIDesignAndPrinciple from './Component/Blogs/UIDesignAndPrinciple';
import DesignProcess from './Component/Blogs/DesignProcess';
import DesignThinking from './Component/Blogs/DesignThinking';
import InformationArchitecture from './Component/Blogs/InformationArchitecture';
import DesignSystem from './Component/Blogs/DesignSystem';
import WireframeMockupPrototype from './Component/Blogs/WireframeMockupPrototype';
import UXResearch from './Component/Blogs/UXResearch';
import MobileAppWebAppWebsite from './Component/Blogs/MobileAppWebAppWebsite';
import JS1 from './Component/Blogs/JavaScript/JS1';
import JS2 from'./Component/Blogs/JavaScript/JS2';
import JS3 from'./Component/Blogs/JavaScript/JS3';
import RJS0 from './Component/Blogs/ReactJS/RJS0';
import RJS1 from './Component/Blogs/ReactJS/RJS1';
import RJS2 from './Component/Blogs/ReactJS/RJS2';
import RJS3 from './Component/Blogs/ReactJS/RJS3';
import RJS4 from './Component/Blogs/ReactJS/RJS4';
import RJS5 from './Component/Blogs/ReactJS/RJS5';
import RJS6 from './Component/Blogs/ReactJS/RJS6';
import RJS7 from './Component/Blogs/ReactJS/RJS7';
import RJS8 from './Component/Blogs/ReactJS/RJS8';
import RX1 from './Component/Blogs/Redux/RX1';
import DP1 from './Component/Portfolio/DP1';
import DP2 from './Component/Portfolio/DP2';
import ShoppingCart from './Component/Portfolio/ShoppingCart';
import Ftbee from './Component/Portfolio/Ftbee';
import Globalbagsandprint from './Component/Portfolio/Globalbagsandprint';
import HospitalPlus from './Component/Portfolio/HospitalPlus';
import NutratrainX from './Component/Portfolio/NutratrainX';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop  from './Component/Common/ScrollToTop';



function App() {
  
  const helmetContext = {};
  
  return (
    <div>
      <HelmetProvider context={helmetContext}>
      <BrowserRouter>
      <ScrollToTop/>
          <Routes>
            <Route  path="/home" element={ <Home />} />
            <Route  path="/about" element={ <About /> } />
            <Route  path="/blogs" element= { <Blogs />} />
            <Route  path="/blog" element= { <Blog />} />
            <Route  path="/portfolio" element= { <Portfolio  /> } />
            <Route  path="/contact" element={ <Contact />} />
            <Route  path="/ourservices" element={ <OurServices />} />
            <Route  path="/webstudio" element={ <WebStudio />} />
            <Route  path="/ws1" element={ <WS1 />} />
            <Route  path="/ws2" element={ <WS2 />} />
            <Route  path="/blog1" element= { <Blog1 />} />
            <Route  path="/usercentereddesign" element={ <UserCenteredDesign />} />
            <Route  path="/uiuxdesign" element={ <UIUXDesign />} />
            <Route  path="/uidesignandprinciple" element={ <UIDesignAndPrinciple />} />
            <Route  path="/designprocess" element={ <DesignProcess />} />
            <Route  path="/designthinking" element={ <DesignThinking />} />
            <Route  path="/informationarchitecture" element={ <InformationArchitecture />} />
            <Route  path="/designsystem" element={ <DesignSystem />} />
            <Route  path="/wireframemockupprototype" element={ <WireframeMockupPrototype />} />
            <Route  path="/uxresearch" element={ <UXResearch />} />
            <Route  path="/mobileappwebappwebsite" element={ <MobileAppWebAppWebsite />} />
            <Route  path="/globalbagsandprint" element={ <Globalbagsandprint />} />
            <Route  path="/JS1" element={ <JS1 />} />
            <Route  path="/js2" element={ <JS2 />} />
            <Route  path="/js3" element={ <JS3 />} />
            <Route  path="/RJS0" element={ <RJS0 />} />
            <Route  path="/rjs1" element={ <RJS1/>} />
            <Route  path="/rjs2" element={ <RJS2/>} />
            <Route  path="/rjs3" element={ <RJS3/>} />
            <Route  path="/rjs4" element={ <RJS4/>} />
            <Route  path="/rjs5" element={ <RJS5/>} />
            <Route  path="/rjs6" element={ <RJS6/>} />
            <Route  path="/rjs7" element={ <RJS7/>} />
            <Route  path="/rjs8" element={ <RJS8/>} />
            <Route  path="/rx1" element={ <RX1/>} />
            <Route  path="/dp1" element={ <DP1 />} />
            <Route  path="/dp2" element={ <DP2 />} />
            <Route  path="/shoppingcart" element={ <ShoppingCart />} />
            <Route  path="/ftbee" element={ <Ftbee />} />
            <Route  path="/hospitalplus" element={ <HospitalPlus />} />
            <Route  path="/nutratrainX" element={ <NutratrainX />} />
            <Route  exact path="/*" element={ <Home />} />
          </Routes>
      </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
