import React, {useState, useEffect} from "react";
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import Markdown from "markdown-to-jsx";	
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import Code from "../../Common/Code";
import RJS1 from './RJS1';
import RJS3 from './RJS3';
import Sidebar from "../Sidebar";
import BackToTopBtn from "../../Common/BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const RJS2 = () => {
    const [postContent0, setPostcontent0] = useState('');
    const [postContent1, setPostcontent1] = useState('');
    const [postContent2, setPostcontent2] = useState('');
    const [isDark, setIsDark] = useState(true);

    useEffect(() => {
       
        import('./../ReactJS-Tutorials/Tutorial3.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent0(response))
            .catch(err => console.log(err))
        )
          import('./../ReactJS-Tutorials/Tutorial4.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent1(response))
              .catch(err => console.log(err))
          )
          import('./../ReactJS-Tutorials/Tutorial5.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent2(response))
              .catch(err => console.log(err))
          )
      }, [])
   
  return (
    <div className="overflow-hidden">
        <Helmet>
            <link rel="icon" href="images/takawale.ico"/>
            <title>React JS Blog Info III</title>
            <meta name="description" content="ReactJs Tutorial Blog"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
            <div className="container">
                <div className="row">
                <div className="col-12 text-center">
                    <h3 className="text-white font-tertiary pt-2">React JS</h3>
                </div>
                </div>
            </div>
        </section>
        <div className=" pagination w-100 mt-3">
            <Routes>
                <Route  path="/rjs1" element={ <RJS1 />} />
                <Route  path="/rjs3" element={ <RJS3 />} />
            </Routes>
        </div>  
        <Nav aria-label="Page navigation example">
            <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/rjs1"><b>Previous</b></NavLink>
            <NavLink className="page-link text-dark rounded btn-primary p-2"  to="/rjs3"><b>Next</b></NavLink>
        </Nav>
        <section className="section mb-200 mx-2">
            <div className="bg-blur rounded">
                <div className="row p-3">
                    <div className="col-lg-12">
                        <p className="text-dark px-4">Published by <span className="text-dark font-Croissant">takawale.com</span></p>
                        <div className="content rounded">
                            <embed src="images/blog/ReactJS.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-100" />
                            <div>
                                <Sidebar/>
                            </div>
                            <h4 className="text-dark pt-5">ReactJS Props</h4>
                            <li>When React sees an element representing a user-defined component, it passes JSX attributes and children to this component as a single object. We call this object “props”.</li>
                           <li>The props are a type of object where the value of attributes of a tag is stored.</li>
                           <li>The word “props” implies “properties”, and its working functionality is quite similar to HTML attributes.</li>
                           <li>Basically, these props components are read-only components.</li>
                           <li>In ReactJS, the data can be passed from one component to another component using these props, similar to how the arguments are passed in a function.</li>
                           <li>Inside the component, we can add the attributes called props; however, we cannot change or modify props inside the component as they are immutable. </li>
                           <li>Using the “this.props”, we can make the props available inside the components.</li>
                           <li>Then, the dynamic data can be rendered through the render method.</li>
                           <li>We need to add the props to the reactDOM.render() in the Main.js file of our ReactJS project of ReactJS if we need immutable data in the component.<br/> 
                           Then we can use it in the component where we want to use those dynamic data.</li>

                           <p>There are two ways to use props: one <b>without destructuring</b> and the other <b>with destructuring.</b></p>
                           <h4 className="text-dark">Destructuring</h4>
                           <p>To illustrate destructuring, we'll make a sandwich. Do you take everything out of the refrigerator to make your sandwich? No, you only take out the items you would like to use on your sandwich.<br/>
                           Destructuring is exactly the same. We may have an array or object that we are working with, but we only need some of the items contained in these.<br/>
                           Destructuring makes it easy to extract only what is needed.</p>

                            <div className="p-2 rounded">
                                <article>
                                    <header>
                                    </header>
                                    <main> 
                                    <Markdown
                                        options={{
                                        overrides: {
                                            Code: {
                                            component: Code,
                                            props: {
                                                isDark,
                                                setIsDark
                                            }
                                            }
                                        }
                                        }}
                                    >
                                        {postContent0}
                                    </Markdown>
                                    </main>
                                </article>
                            </div>

                            <h4 className="text-dark">Use React Props in Class Components?</h4>
                            <p> Although <b>Class Components</b> are no longer the default way of working with React, there are still a lot of legacy applications using class components.</p>
                            <p><b>Let's look how to work with props in class components specifically.</b></p>

                            <p>In class components, props are available on the <b>props</b> object that’s accessible via the this keyword. You can simply access them like you would access properties of any other object.</p>
                            <div className="p-2 rounded">
                            <article>
                                <header>
                                </header>
                                <main> 
                                <Markdown
                                    options={{
                                    overrides: {
                                        Code: {
                                        component: Code,
                                        props: {
                                            isDark,
                                            setIsDark
                                        }
                                        }
                                    }
                                    }}
                                >
                                    {postContent1}
                                </Markdown>
                                </main>
                            </article>
                        </div>
                        <h4 className="text-dark">ReactJS State</h4>
                        <li>The state is a built-in React object that is used to contain data or information about the component.<br/> 
                        A component’s state can change over time; whenever it changes, the component re-renders. The change in state can happen as a response to user action or system-generated events and these changes determine the behavior of the component and how it will render. </li>
                        <li>A state can be modified based on user action or network changes</li>
                        <li>Every time the state of an object changes, React re-renders the component to the browser</li>
                        <li>The state object is initialized in the constructor</li>
                        <li>The state object can store multiple properties</li>
                        <li>this.setState() is used to change the value of the state object</li>
                        <li>setState() function performs a shallow merge between the new and the previous state</li>

                        <h4 className="text-dark">The setState() Method</h4>
                        <p>State can be updated in response to event handlers, server responses, or prop changes. This is done using the setState() method.<br/>
                        The setState() method enqueues all of the updates made to the component state and instructs React to re-render the component and its children with the updated state.</p>

                        <p>Always use the setState() method to change the state object, since it will ensure that the component knows it’s been updated and calls the render() method.</p>
                        <p>Now that we are familiar with the concept of a state in React, let’s have a look at how it is implemented in a React web application.</p>
                        <div className="p-2 rounded">
                            <article>
                                <header>
                                </header>
                                <main> 
                                <Markdown
                                    options={{
                                    overrides: {
                                        Code: {
                                        component: Code,
                                        props: {
                                            isDark,
                                            setIsDark
                                        }
                                        }
                                    }
                                    }}
                                >
                                    {postContent2}
                                </Markdown>
                                </main>
                            </article>
                        </div>

                        <h4 className="text-dark">State vs. Props</h4>
                        <tabel className="table text-dark">
                            <tbody>
                                <tr>
                                    <th scope="col" className="border border-dark border-right rounded">
                                       State       
                                    </th>
                                    <th scope="col" className="border border-dark border-right rounded">
                                       Props
                                    </th>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    State is used to store the data of the components that have to be rendered to the view
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    Props are used to pass data and event handlers to the children components
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    State holds the data and can change over time
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    Props are immutable—once set, props cannot be changed
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    State can only be used in class components
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    Props can be used in both functional and class components
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    Event handlers generally update state
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    The parent component sets props for the children components
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    Will accept any type of data (props)
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    Support props
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    Don’t support a constructor
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    Require a constructor to store state before they can be used to pass props to the parent class
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    Return HTML elements or nothing
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    Require a render function that returns an HTML element
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    Support React 16.8 hooks
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                   
                                    </td>
                                </tr>
                            </tbody>
                        </tabel>  

                        <div className=" pagination w-100">
                            <Routes>
                                <Route  path="/rjs1" element={ <RJS1 />} />
                                <Route  path="/rjs3" element={ <RJS3 />} />
                            </Routes>
                        </div>  
                        <Nav aria-label="Page navigation example">
                            <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/rjs1"><b>Previous</b></NavLink>
                            <NavLink className="page-link text-dark rounded btn-primary p-2"  to="/rjs3"><b>Next</b></NavLink>
                        </Nav>
                        </div>
                    
                    </div>
                </div>
            </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
    )
}

export default RJS2

