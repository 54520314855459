import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Nav from 'react-bootstrap/Nav';
import { Route, NavLink, Routes} from "react-router-dom";
import ShoppingCart from './ShoppingCart';
import NutratrainX from './NutratrainX';
import Ftbee from './Ftbee';
import Globalbagsandprint from "./Globalbagsandprint";


const DP1 = () => {
  
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
      
      <section className="mx-1 mt-3 rounded shadow-lg">
        
        <div className="bg-blur rounded p-3">
        <div className="col-lg-12 col-12 mb-4 shuffle-item mx-auto p-3 rounded " data-groups="[&quot;design&quot;,&quot;illustration&quot;]">
            <div className="position-relative rounded hover-wrapper"> 
              <div className="row  shuffle-wrapper">
                <div className='col-lg-6'>
                  <embed src="images/portfolio/globalbagsandprint/globalbagsandprint.com.png" alt="" className="img-fluid w-100 h-100 rounded shadow-lg"/>
                </div> 
                <div className='col-lg-6'> 
                  <p className='text-dark py-2 px-2  border shadow rounded my-2 mx-2'>
                    <span className='text-uppercase'>Intro : </span>
                    <span>
                    globalbagsandprint.com is a website designed and developed for a Pune based packaging product manufacturing company.
                    This website is custom designed to effectively cater the requirnments set forth by the customer. Throw research of the industry the client is associated with is done once we know the industry. In depth requirment is gathered from the client in advance before starting the initial design process and content writing work.
                    This website showcase details of the products the company manufatures, the prodution process, the machinery used for manufacturing the products, company details and ceo/owner details. 
                    This website is designed and developed using modern framework, latest design standards and trends. <br/> This is a systematically designed static website which can be easily modified and converted to a dynamic website without much efforts and complexity. 
                     <p className="p-2 my-4 rounded btn btn-xs fw-bold w-25 nav-link"><a href='https://www.globalbagsandprint.com' target = "_blank" rel="noreferrer">Live Demo</a></p>
                      <Nav>
                      <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/globalbagsandprint"><b>Read More</b></NavLink>
                      </Nav>
                      <Routes>
                          <Route  path="/globalbagsandprint" element={ <Globalbagsandprint />} />
                      </Routes>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-12 mb-4 shuffle-item mx-auto p-3 rounded " data-groups="[&quot;design&quot;,&quot;illustration&quot;]">
            <div className="position-relative rounded hover-wrapper"> 
              <div className="row  shuffle-wrapper">
                <div className='col-lg-6'>
                  <embed src="images/portfolio/shopping-cart/shopping-cart.jpg" alt="" className="img-fluid w-100 h-100 rounded shadow-lg"/>
                </div> 
                <div className='col-lg-6'> 
                  <p className='text-dark py-2 px-2  border shadow rounded my-2 mx-2'>
                    <span className='text-uppercase'>Intro : </span>
                    <span>
                      Online shopping cart is the place to go if you as a customer like to do online shopping.
                      Vendor can host item on the site based on item category. Vendor can even manage item category.
                      We have Home page  where customer can see the product catalogue, choose the product from the catalogue,
                      click the product to see products details. manage order in the cart, maintain customer details and manage customer login, also you can change the them of the website at runtime.
                      <Nav>
                      <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/shoppingcart"><b>Read More</b></NavLink>
                      </Nav>
                      <Routes>
                          <Route  path="/shoppingcart" element={ <ShoppingCart />} />
                      </Routes>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
            <div className="col-lg-12 col-12 mb-4 shuffle-item mx-auto p-3 rounded" data-groups="[&quot;design&quot;,&quot;illustration&quot;]" data-aos="fade-down">
              <div className="position-relative rounded hover-wrapper"> 
                <div className="row">
                  <div className='col-lg-6'>
                    <embed src="images/portfolio/ftbee-web-app/1.png" alt="" className="img-fluid w-100 h-100 rounded"/>
                  </div> 
                  <div className='col-lg-6'> 
                    <p className='text-dark py-2 px-2 border shadow rounded my-2 mx-2'>
                      <span className='text-uppercase'>Intro : </span>
                      <span>
                        Ftbee "Bee Foodie. Be Trendy." blogger website. This website is dedicated to food and latest trends. This website promots the idea of spending time with your loved ones, creating your space where you can spend time with your family and frends. Dine with your family at your home rather visiting a restaurent cook different food dishes with your family at your home, travel destinations with your family and friends, Know what is good and what is not from what you get in the markert may it be food products, goods for your daily use, or goods you need for your home.<br/>
                        This website is easy to use and understand for a new non-technical user.
                        With the requirement we gathered from our research and survey, we decided to develop a website that is user-friendly, contain easy information access, is easy to maintain, clean and classic design focus on attracting potential user and ultimately focus on increasing user traffic.
                        <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/ftbee"><b>Read More</b></NavLink>
                        </Nav>
                        <Routes>
                            <Route  path="/ftbee" element={ <Ftbee />} />
                        </Routes>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          <div className="col-lg-12 col-12 mb-4 shuffle-item mx-auto p-3 rounded" data-groups="[&quot;design&quot;,&quot;illustration&quot;]" data-aos="fade-down">
            <div className="position-relative rounded hover-wrapper"> 
              <div className="row">
                <div className='col-lg-6'>
                  <embed src="images/portfolio/nutratrainX/nutratrainX.png" alt="" className="img-fluid w-100 h-100 rounded shadow-lg"/>
                </div> 
                <div className='col-lg-6'> 
                  <p className='text-dark py-4 px-2 border shadow rounded my-2 mx-2'>
                    <span className='text-uppercase'>Intro : </span>
                    <span>
                    NutratrainX was the website developed in for the year 2022-2023. This website was developed for the nutratrainX a personal trainer organization.
                    </span><br/>
                    <span>
                      NutratrainX is a single-page website designed for professional Fitness trainer and a Fitness consultant. The website is purely designed to showcase the professional presence of an individual on a digital platform.
                        <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/nutratrainX"><b>Read More</b></NavLink>
                        </Nav>
                        <Routes>
                            <Route  path="/nutratrainX" element={ <NutratrainX />} />
                        </Routes>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
      </section>
    </div>
  )
}

export default DP1
