import React from 'react';
import PortfolioTabs from './PortfolioTabs';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import BackToTopBtn from '../Common/BackToTopBtn';
import { Helmet } from 'react-helmet-async';

const Portfolio = () => {
  return (
    <div>
      <Helmet>
        <link rel="icon" href="images/takawale.ico"/>
        <title>Portfolio | The Journey Of My Work</title>
        <meta name="description" content="Freelancer specializing in web design and development, graphic design, and UI/UX services. I deliver innovative, user-friendly digital solutions that enhance your brand and engage users. Let’s work together to create something exceptional!"/>
        <meta name="keywords" content='shopping cart, blogger website, web app'/>
      </Helmet>
      <Header/>
      <section classNameName="heading-area" id="parallax">
      </section>

      <section className="mb-200">
        <div className="bg-primary position-relative">
          <div className="container">
              <div className="row">
              <div className="col-12 text-center">
                  <h3 className="text-white font-tertiary pt-2">Portfolio</h3>
              </div>
              </div>
          </div>
        </div>
        <PortfolioTabs />
      </section>
      <BackToTopBtn/>
      <Footer/>
    </div>
  )
}

export default Portfolio
