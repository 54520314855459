import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import BackToTopBtn from '../Common/BackToTopBtn';
import BackButton from '../Common/BackButton';
import { Helmet } from 'react-helmet-async';

const Globalbagsandprint = () => {
  return (
      <div>
        <Helmet>
          <link rel="icon" href="images/takawale.ico"/>
          <title>hospital management app</title>
          <meta name="description" content="Info of hospital management app"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
        <div className="container">
            <div className="row">
            <div className="col-12 text-center">
              <h3 className="text-white font-tertiary pt-2">Industrial Website(For manufacturing company)</h3>
            </div>
            </div>
        </div>
        
        
        </section>


        <section className="section mb-200 mx-2">
          <div className="bg-blur rounded">
            <div className="row p-3">
              <div className="col-lg-12">
                <div className="content p-3 rounded"> 
                  <h4 className="text-dark text-center"><b>Responsive Website Design, and Website Development</b></h4>
                  <embed src="images/portfolio/globalbagsandprint/mobilescreen.png" alt="post-thumb" className="img-fluid p-3 w-100" />
                  <hr></hr>
                  <embed src="images/portfolio/globalbagsandprint/tabletscreen.png" alt="post-thumb" className="img-fluid p-3 w-100" />
                  <hr></hr>
                  <embed src="images/portfolio/globalbagsandprint/computerscreen.png" alt="post-thumb" className="img-fluid p-3 w-100" />
                  <hr></hr>
                    <p> <b>Overview:</b> 
                    <li>globalbagsandprint.com is a website designed and developed for a Pune based packaging product manufacturing company.
                    This website is custom designed to effectively cater the requirnments set forth by the customer. Throw research of the industry the client is associated with is done once we know the industry. In depth requirment is gathered from the client in advance before starting the initial design process and content writing work.
                    This website showcase details of the products the company manufatures, the prodution process, the machinery used for manufacturing the products, company details and ceo/owner details. 
                    This website is designed and developed using modern framework, latest design standards and trends.</li>
                    <li>This is a systematically designed static website which can be easily modified and converted to a dynamic website without much efforts and complexity.</li>
                    <li>The website contain pages that showcase specific information under specified pages allowing you to display different aspects of companies business and the manufacturing Process of the company, making it easy for the business partners, associates, and the clients of the company understand companies ideology and its work culture in depth.
                        This helps boost confidence of business partners, associates, and the clients which ultimately help enhance the overall business of the company.</li></p>
                    <p><b>Technologies Used:</b> 
                        <li>React JS</li>
                        <li>JavaScript</li>
                        <li>HTML5</li>
                        <li>SCSS</li>
                        <li>Bootstrap</li>
                        <li>CSS3</li>
                        <li>Firebase</li>
                    </p>
                    <p><b>My Role:</b> 
                        <li>Designed the UI/UX of the website. </li>
                        <li>Developed the frontend using HTML5, Javascript, ReactJS. </li>
                        <li>Made it responsive using CSS, Bootstrap and Media queries</li>
                    </p>
                    <div className="row pt-3">
                        <p className="py-2 m-4 rounded btn btn-xs fw-bold w-10"><a href='https://www.globalbagsandprint.com' target = "_blank" rel="noreferrer">Live Demo</a></p>
                        <BackButton/>
                    </div>
                </div>
                  
              </div>
            </div>
          </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
  )
}

export default Globalbagsandprint
