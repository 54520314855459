import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Header from './Common/Header';
import Footer from './Common/Footer';
import BackToTopBtn from "./Common/BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const OurServices = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    
  return (
    <div>
        <Helmet>
            <link rel="icon" href="images/takawale.ico"/>
            <title>Our Services | Creative and Functional – UI/UX, Frontend and Web Development Services</title>
            <meta name="description" content=" Graphics Designing, UI/UX Designing, Web development, Frontend Development"/>
            <meta name="keywords" content=' Graphics Designing, UI/UX Designing, Web development, Frontend Development'/>
        </Helmet>
        <Header/>
        <section className=" bg-primary">
            <div className="container">
                <div className="row">
                <div className="col-12 text-center">
                    <h3 className="text-white font-tertiary pt-2">services we Offer</h3>
                </div>
                </div>
            </div>
        </section>
        <section className="section pt-4 mb-200" data-aos="fade-down">
        <div className="bg-blur p-3 rounded shadow-lg mx-2">
            <div className="row justify-content-around p-3 rounded">
            <div className="col-lg-12 py-3">
                <p className="fs-4">Elevate your brand’s presence with my comprehensive design and development services. From stunning logos and branding materials to custom print designs, web design, and development, I craft visually compelling and user-friendly solutions tailored to your business. I specialize in creating responsive websites with intuitive navigation and a strong brand identity—ensuring your digital and print assets leave a lasting impact and drive real results.</p>
            </div>
            <div className="col-lg-4 col-md-4 rounded border border-light" data-aos="fade-down">
                <article className="pt-4">
                <embed src="images/icons/uidesigning.png" className="icon w-100 rounded" alt="icon" />
                </article>
                <article  className='text-dark px-3'>
                <p className="fs-5  justify-content-center rounded-bottom text-uppercase fw-bold">UI/UX Designing Services</p>
                <p><embed src="images/icons/wireframe.png" className="icon w-25 rounded" alt="icon" /><br/><b>Wireframing:</b> This is a key step in the design process, where designers plan the content structure and map the information architecture. .</p>
                <p><embed src="images/icons/visualdesign.png" className="icon w-25 rounded" alt="icon" /><br/><b>Visual design:</b> This includes using images, colors, icons, fonts, and typography to improve the aesthetic appeal of the software application. </p>
                <p><embed src="images/icons/interactiondesign.png" className="icon w-25 rounded" alt="icon" /><br/><b>Interaction design:</b> This focuses on creating intuitive and easy-to-use interfaces, such as buttons and menus. </p>
                <p><embed src="images/icons/userresearch.png" className="icon w-25 rounded" alt="icon" /><br/><b>User research:</b> This provides designers with information and ideas for design.</p>
                <p><embed src="images/icons/prototyping.png" className="icon w-25 rounded" alt="icon" /><br/> <b>Prototyping:</b> This allows designers to test ideas before fully developing them.</p>
                </article>
            </div>
            <div className="col-lg-4 col-md-4 pt-1 rounded border border-light" data-aos="fade-down">
                <article className="pt-4">
                <embed src="images/icons/webdevelopment.png" className="icon w-100 rounded" alt="icon" />
                </article>
                <article  className='text-dark px-3'>
                <p className="fs-5  justify-content-center rounded-bottom text-uppercase fw-bold">Web Designing & Development Services</p>
                <p><b>Core Design Services: </b>
                    <li>Custom website design </li>
                    <li>Responsive design (adapting to different devices)</li> 
                    <li>User experience (UX) design </li>
                    <li>Visual design (color palette, typography, layout) </li>
                    <li>Landing page design </li>
                    <li>Logo design</li> 
                </p>
                <p><b>Development Services: </b> 
                    <li>Front-end development (HTML, CSS, JavaScript)</li> 
                    <li>Back-end development (server-side scripting languages) </li>
                    {/*<li>CMS integration (WordPress, Drupal, etc.)</li>
                    <li>E-commerce development</li> */} 
                    <li>Application development</li>
                </p> 
                <p><b>Maintenance and Support: </b>
                    <li>Website maintenance and updates </li>
                    <li>Hosting services </li>
                    <li>Technical support</li>
                </p>
                </article>
            </div>
            <div className="col-lg-4 col-md-4 pt-1 rounded border border-light" data-aos="fade-down">
                <article className="pt-4">
                <embed src="images/icons/branding.png" className="icon w-100 rounded" alt="icon"/>
                </article>
                <article  className='text-dark px-3'>
                <p className="fs-5  text-dark justify-content-center rounded-bottom text-uppercase fw-bold">Graphic Design Services</p>
                <p><b>Branding: </b> <br/>Create a unified visual identity for a brand by establishing brand guidelines that use consistent colors, typography, and imagery. </p>
                <p><b>Logo design: </b>  <br/>Create a logo that matches the personality and profession of the business.</p>
                <p><b>Packaging design: </b>  <br/>Create attractive and functional packaging that can serve as free advertising.</p>
                <p><b>Web and mobile design: </b> <br/>Create user interfaces for apps and websites. </p>
                <p><b>Print design: </b> <br/>Create the layout and production design for print media like brochures, magazines, and reports. </p>
                <p><b>Social media graphics: </b> <br/>Create graphics for social media. </p>
                <p><b>Signage design: </b> <br/>Create large-scale graphics for advertising, events, or informational purposes.</p>
                </article>
            </div>
            </div>
        </div>
        
        </section>
        <BackToTopBtn />
        <Footer/>
    </div>
  )
}

export default OurServices
