import { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { FaList } from "react-icons/fa";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarHeader,
  SubMenu
} from "react-pro-sidebar";
import "../../SidebarStyle.css";
import styled from "styled-components";

const Menuitem = styled(MenuItem)`
  :hover {
    padding: 5px;
    border-radius: 10px;
  }
`;

const SideNavigation = () => {
  const [collapsed, setCollapsed] = useState(true);
  const styles = {
    sideBarHeight: {
      height: "145vh"
    },
    menuIcon: {
      float: "left",
      margin: "5px 4px 0px 26px",
    }
  };
  const onClickMenuIcon = () => {
    setCollapsed(!collapsed);
  };
  return (
    <ProSidebar style={styles.sideBarHeight} collapsed={collapsed}>
      <SidebarHeader>
        <div style={styles.menuIcon} onClick={onClickMenuIcon}>
        <div className="xbootstrap font-Croissant text-uppercase bg-warning rounded"><AiOutlineMenu className="m-1"/></div>
        </div>
      </SidebarHeader>
      <Menu iconShape="square">
        <SubMenu title="PAGE I" icon={<FaList />}>
        <Link to="/SBlog12">
          <Menuitem>Introduction</Menuitem>
          <MenuItem>JSX</MenuItem>
          <MenuItem>Real DOM VS Virtual DOM</MenuItem>
          <MenuItem>Installing ReactJS And <br/> Creating New React Project</MenuItem>
        </Link>
        </SubMenu>
        
        <SubMenu title="PAGE II" icon={<FaList />}>
        <Link to="/RJS1">
          <Menuitem>Introduction To Components</Menuitem>
          <MenuItem>Functional Components</MenuItem>
          <MenuItem>Class Components</MenuItem>
          <MenuItem>What Component Should You Use</MenuItem>
          <MenuItem>Thought Process While creating <br/>Components</MenuItem>
          <MenuItem>Mistakes To Avoid While Creatng <br/>Components</MenuItem>
        </Link>  
        </SubMenu>
        <SubMenu title="PAGE III" icon={<FaList />}>
        <Link to="/RJS2">
          <Menuitem>React JS Props</Menuitem>
          <MenuItem>Destructuring</MenuItem>
          <MenuItem>Use React Props In Class <br/>Components?</MenuItem>
          <MenuItem>ReactJS State In Class <br/>Components</MenuItem>
          <MenuItem>State VS Prop</MenuItem>
        </Link>
        </SubMenu>
        <SubMenu title="PAGE IV" icon={<FaList />}>
        <Link to="/RJS3">
          <Menuitem>React JS Hooks</Menuitem>
          <MenuItem>useState Hook</MenuItem>
          <MenuItem>Updating Objects and Arrays in <br/> State</MenuItem>
        </Link>
        </SubMenu>
        <SubMenu title="PAGE V" icon={<FaList />}>
        <Link to="/RJS4">
          <Menuitem>useEffect Hook</Menuitem>
          <MenuItem>What Exactly Is An "effect"?</MenuItem>
          <MenuItem>How do I use useEffect?</MenuItem>
          <MenuItem>Avoid Common Mistakes In <br/>useEffect</MenuItem>
          <MenuItem>What Is The Cleanup Function <br/>In useEffect?</MenuItem>
          <MenuItem>useContext Hook</MenuItem>
          <MenuItem>Props VS Context</MenuItem>
          <MenuItem>How The useReducer Hook<br/> Works</MenuItem>
          <MenuItem>What Is Dispatch In useReducer?</MenuItem>
          <MenuItem>useMemo</MenuItem>
          <MenuItem>useCallback</MenuItem>
          </Link>
        </SubMenu>
        <SubMenu title="PAGE VI" icon={<FaList />}>
        <Link to="/RJS5">
          <Menuitem>useDebugValue Hook</Menuitem>
          <MenuItem>useRef Hook</MenuItem>
          <MenuItem>useLayoutEffect Hook</MenuItem>
          <MenuItem>useLayoutEffect VS <br/>useEffect Hook</MenuItem>
          <MenuItem>useTransition Hook</MenuItem>
          <MenuItem>useDeferredValue Hook</MenuItem>
          <MenuItem>useId Hook</MenuItem>
          <MenuItem>When To Use The useId Hook <br/>And When Not To</MenuItem>
        </Link>
        </SubMenu>
        <SubMenu title="PAGE VII" icon={<FaList />}>
        <Link to="/RJS6">
          <MenuItem>useSyncExternalStore</MenuItem>
          <MenuItem>useInsertionEffect</MenuItem>
          <MenuItem>useImperativeHandle</MenuItem>
        </Link>
        </SubMenu>
        <SubMenu title="PAGE VIII" icon={<FaList />}>
        <Link to="/RJS7">
          <MenuItem>Higher-Order Functions <br/>In JavaScript</MenuItem>
          <MenuItem>EXAMPLE OF CUSTOM <br/>HIGHER-ORDER FUNCTION</MenuItem>
          <MenuItem>What Is A Higher-Order <br/>Component?</MenuItem>
          <MenuItem>Build A Higher-Order <br/>Component</MenuItem>
        </Link>
        </SubMenu>
        <SubMenu title="PAGE IX" icon={<FaList />}>
        <Link to="/RJS8">
          <MenuItem>React Router</MenuItem>
          <MenuItem>React Router Installation</MenuItem>
          <MenuItem>Setup React Router</MenuItem>
          <MenuItem>Configure Routes In <br/>React</MenuItem>
          <MenuItem>Configured Routes <br/>with Links</MenuItem>
          <MenuItem>Implement Active Links</MenuItem>
          <MenuItem>Navigate Programmatically<br/> in React</MenuItem>
          <MenuItem>Implement Lazy Loading <br/>with React Router</MenuItem>
        </Link>
        </SubMenu>
      </Menu>
    </ProSidebar>
  );
};
export default SideNavigation;
