import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function Carouselslider() {

  return (
    <Carousel id="carouselExampleSlidesOnly" data-ride="carousel" className='carousel slide mt-1 hover-wrapper rounded carousel-bg Carousel-image'>
      <Carousel.Item interval={8000}>
        <div className='row align-items-centr h-100'>
          <div className='col-lg-6 col-md-6 col-sm-10 mx-auto mb-5 py_5 px-4 pt-5'>
            <p className="text-dark justify-content-center fs-1">Hi, I'm Swapnil</p>
          <p className="text-dark justify-content-center fs-4 mb-4">Graphic designer, UI designer, and frontend developer based in Pune. Open to both freelance and full-time opportunities!</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'> <embed 
              className=" h75 w-100 rounded-circle shadow-lg"
              src="images/testimonial/image3.png"
              alt="First slide"
            />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={8000}>
        <div className='row align-items-centr h-100'>
          <div className='col-lg-6 col-md-6 col-sm-10 mx-auto pt-5'>
            <p className="text-dark justify-content-center fs-4 px-4 py_5 mb-4">I am passionate about blending creativity with technology, I design and develop high-performance websites that leave a lasting impact.</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'><embed
              className=" h75 w-100 rounded-circle shadow-lg"
              src="images/image3.png"
              alt="Second slide"/>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={8000}>
        <div className='row align-items-centr h-100'>
          <div className='col-lg-6 col-md-6 col-sm-10 mx-auto pt-5 py_5 mb-4'>
            <p className="text-dark justify-content-center fs-4 px-4">I craft smarter, easy-to-use user interfaces and create elegant, interactive web apps, websited, graphic designs, and UI designs that have the ability to engage the customers with the brand.</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'> <embed 
              className=" h75 w-100 rounded-circle shadow-lg"
              src="images/image5.png"
              alt="First slide"
            />
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default Carouselslider;